import { Nlp } from '@nlpjs/nlp';
import fs from '../utils/fetch-fs';
export var config = {
    settings: {
        corpora: [
            require('../static/corpora/assessor-therapy-corpus-1.json'),
            require('../static/corpora/feeling-corpus.json'),
            require('../static/corpora/flags-corpus.json'),
        ],
        nlp: {
            corpora: []
        },
    },
    use: [["fs", fs], Nlp],
};
