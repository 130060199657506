import React, { createContext, useContext } from "react";
import { useLocalStorage } from "../utils/use-local-storage";

const AppContext = createContext(undefined);

export const useAppContext = () => useContext(AppContext);

export const AppContextProvider = ({ children }) => {
  return (
    <AppContext.Provider
      value={{
        chatHistory: useLocalStorage('chatHistory', {}),
        keys: useLocalStorage('keys', undefined),
      }}>
      {children}
    </AppContext.Provider>
  );
};