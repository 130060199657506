import { ask, condition, run, say } from "../../bot/bot-schema";
import setState from '../../actions/set-state';
import assessresponse from '../../actions/assess-response';
export default {
    assessor_gad7: [
        setState({ "gad7_tally_sum": 0 }),
        setState({ "gad7_tally": undefined }),
        setState({ "last_question": false }),
        say('This questionnaire is important in order to provide you with the best possible health care. Your answers will facilitate the understanding of problems that you may have.'),
        say('In the past 2 weeks, how often have you been bothered by any of the following problems?'),
        run('gad7_Q1'),
    ],
    assess_gad7_response: [
        ask('response'),
        assessresponse({ input: "response", question_name: "gad7_question", tally_name: "gad7_tally" }),
        condition(function (ctx) { return ctx.response_unclear; }, say('{{ response_unclear }}')),
        condition(function (ctx) { return ctx.response_unclear; }, run('assess_gad7_response')),
        condition(function (ctx) { return !ctx.response_unclear && !ctx.last_question; }, say('Thanks, next question')),
    ],
    gad7_Q1: [
        setState({ "gad7_question": 1 }),
        say('Feeling nervous, anxious or on edge?'),
        run('assess_gad7_response'),
        run('gad7_Q2'),
    ],
    gad7_Q2: [
        setState({ "gad7_question": 2 }),
        say('Not being able to stop or control worrying?'),
        run('assess_gad7_response'),
        run('gad7_Q3'),
    ],
    gad7_Q3: [
        setState({ "gad7_question": 3 }),
        say('Worrying too much about different things?'),
        run('assess_gad7_response'),
        run('gad7_Q4'),
    ],
    gad7_Q4: [
        setState({ "gad7_question": 4 }),
        say('Trouble relaxing?'),
        run('assess_gad7_response'),
        run('gad7_Q5'),
    ],
    gad7_Q5: [
        setState({ "gad7_question": 5 }),
        say('Being so restless that it is hard to sit still?'),
        run('assess_gad7_response'),
        run('gad7_Q6'),
    ],
    gad7_Q6: [
        setState({ "gad7_question": 6 }),
        say('Becoming easily annoyed or irritable?'),
        run('assess_gad7_response'),
        run('gad7_Q7'),
    ],
    gad7_Q7: [
        setState({ "gad7_question": 7 }),
        say('Feeling afraid as if something awful might happen?'),
        setState({ "last_question": true }),
        run('assess_gad7_response'),
        run('gad7_end'),
    ],
    gad7_end: [
        say('Thanks for your responses.'),
        condition(function (context) { return Number(context.gad7_tally_sum) >= 5 && Number(context.gad7_tally_sum) <= 9; }, say('Your assessment indicates that you may be experiencing Mild Anxiety. If you are concerned about your mental health, please contact your doctor or a mental health professional')),
        condition(function (context) { return Number(context.gad7_tally_sum) >= 10 && Number(context.gad7_tally_sum) <= 14; }, say('Your assessment indicates that you may be experiencing Moderate Anxiety. If you are concerned about your mental health, please contact your doctor or a mental health professional.')),
        condition(function (context) { return Number(context.gad7_tally_sum) <= 4; }, say('Your assessment indicates that you may be experiencing No Anxiety. If you are concerned about your mental health, please contact your doctor or a mental health professional.')),
        condition(function (context) { return Number(context.gad7_tally_sum) >= 15; }, say('Your assessment indicates that you may be experiencing Severe Anxiety. If you are concerned about your mental health, please contact your doctor or a mental health professional.')),
    ],
};
