var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect } from 'react';
import { bootstrap } from '../bot/bootstrap';
import { AlwaysScrollToBottom } from './AlwaysScrollToBottom';
import { Button, Input } from '@mui/material';
import { awaitTimeout } from '../utils/await-timeout';
import { uuidv4 } from '../utils/get-uuid-v4';
import MessageHistory from './MessageHistory';
import save from '../utils/save';
var Bot = function (params) {
    var mainDialog = params.mainDialog;
    var _a = useState([]), messages = _a[0], setMessages = _a[1];
    var _b = useState(''), text = _b[0], setText = _b[1];
    var _c = useState(false), botThinking = _c[0], setBotThinking = _c[1];
    var _d = useState(undefined), connector = _d[0], setConnector = _d[1];
    var appendMessage = function (message) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setMessages(function (existing) { return __spreadArray(__spreadArray([], existing, true), [message], false); });
            return [2 /*return*/];
        });
    }); };
    var onBotSay = function (message) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setBotThinking(true);
                    return [4 /*yield*/, awaitTimeout(1000)];
                case 1:
                    _a.sent();
                    setBotThinking(false);
                    appendMessage(message);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        var initialise = function () { return __awaiter(void 0, void 0, void 0, function () {
            var bot, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, bootstrap];
                    case 1:
                        bot = _b.sent();
                        _a = setConnector;
                        return [4 /*yield*/, bot.getConnector(onBotSay)];
                    case 2:
                        _a.apply(void 0, [_b.sent()]);
                        return [4 /*yield*/, bot.start(mainDialog)];
                    case 3:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        initialise();
        return function () {
            var dispose = function () { return __awaiter(void 0, void 0, void 0, function () {
                var bot;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, bootstrap];
                        case 1:
                            bot = _a.sent();
                            bot.reset();
                            return [4 /*yield*/, save()];
                        case 2:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); };
            dispose();
        };
    }, []);
    var onSend = function () { return __awaiter(void 0, void 0, void 0, function () {
        var message;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    message = text;
                    setText('');
                    if (!(message === null || message === void 0 ? void 0 : message.trim().length)) {
                        return [2 /*return*/];
                    }
                    appendMessage({
                        date: new Date().toISOString(),
                        id: uuidv4(),
                        isUser: true,
                        text: message,
                    });
                    return [4 /*yield*/, (connector === null || connector === void 0 ? void 0 : connector.onHear(message))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var onKeyPress = function (ev) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(ev.key === 'Enter')) return [3 /*break*/, 2];
                    ev.preventDefault();
                    return [4 /*yield*/, onSend()];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var onChange = function (ev) {
        setText(ev.target.value);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex flex-col" },
            React.createElement("div", { id: "chat-history", className: "overflow-y-auto" },
                React.createElement(MessageHistory, { messages: messages }),
                botThinking && React.createElement("div", { className: "relative flex flex-col items-start mb-1" },
                    React.createElement("div", { id: 'bot-typing', className: "bg-gray-300 inline-block p-2 rounded-xl rounded-bl-none w-20 text-gray-500" }, "Typing...")),
                React.createElement("div", { className: 'h-24' }),
                React.createElement(AlwaysScrollToBottom, null)),
            React.createElement("div", { className: "fixed bottom-0 w-8/12 flex flex-row flex-grow justify-between bg-gray-100 py-4" },
                React.createElement(Input, { type: 'text', onChange: onChange, onKeyDown: onKeyPress, value: text, fullWidth: true, placeholder: 'Message', inputProps: {
                        "aria-autocomplete": 'none',
                        autoComplete: 'off'
                    }, sx: { mr: 2 }, id: 'input-text' }),
                React.createElement(Button, { disabled: botThinking, onClick: onSend, style: {
                        backgroundColor: "".concat(botThinking ? '#cfcfff' : '#7f7fff'),
                        color: 'white',
                        textTransform: 'none'
                    }, id: 'button-send' }, "Send")))));
};
export default Bot;
