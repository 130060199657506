import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import MessageHistory from './MessageHistory';
import { useAppContext } from './StateProvider';

const ChatHistory = (props) => {
  const context = useAppContext();
  const [chatHistory, setChatHistory] = context.chatHistory;
  const [chatList, setChatList] = useState([]);
  const [selectedChat, setSelectedChat] = useState();

  useEffect(() => {
    const chatList = Object.values(chatHistory).filter((chat) => chat.messages.length);
    setChatList(chatList);

    if (!selectedChat) {
      setSelectedChat(chatList?.[0]?.conversationId);
    }
  }, [chatHistory]);

  return (
    <>
      <div className='h-36 overflow-y-auto flex flex-col'>
        <Typography
          variant='h5'
        >
          Conversations
        </Typography>
        <div className='w-full'>
          {chatList.map(({ messages, conversationId, dateStarted }) => (
            <div
              onClick={() => setSelectedChat(conversationId)}
              className='bg-white my-2 p-2 cursor-pointer hover:bg-gray-400 rounded-md'
              key={conversationId}
            >
              <div className='flex justify-between'>
                <span>
                  {conversationId}
                </span>
                <span>
                  {dateStarted}
                </span>
              </div>
            </div>
          ))}
        </div>

      </div>
      <div className='mt-10'>
        <div className='grid grid-cols-2 gap-2'>
          <div className=''>
            <Typography
              variant='h5'
            >
              Transcript
            </Typography>
            {selectedChat && <div className='bg-white p-2 rounded-md mt-6 max-h-96 overflow-y-auto'>
              <MessageHistory messages={chatHistory?.[selectedChat]?.messages ?? []} />
            </div>}
          </div>
          <div className=''>
            <Typography
              variant='h5'
            >
              Context
            </Typography>
            {selectedChat && <div className='bg-white p-2 rounded-md mt-6 overflow-hidden'>
              <pre>
                {JSON.stringify(chatHistory?.[selectedChat]?.context ?? {}, null, 2)}
              </pre>
            </div>}
          </div>
        </div>
      </div>
    </>
  );
}

export default ChatHistory;
