import { ask, condition, run, say } from "../../bot/bot-schema";
import randomOption from "../../actions/random-option";
import getRandom from "../../actions/get-random";
// import getContext from '../../actions/get-context';
import toLowers from "../../actions/to-lowers";
import rapid from "../../actions/rapid";
import summarise from "../../actions/summarise";
// import toSecondPerson from "../../actions/to-second-person";
export default {
    cbt: [
        // getContext({ key: "feeling", from: "earliest", out: "first_feeling" }),
        // getContext({ key: "feeling", from: "latest", out: "last_feeling" }),
        // getRandom({ out: "first_feeling, last_feeling", p: 0.01 }),
        // condition(({ first_feeling, last_feeling }) => first_feeling && first_feeling !== last_feeling,
        //   say('When we first spoke you were feeling {{ first_feeling }}')),
        // getRandom({ out: "last_feeling", p: 0.01 }),
        // condition(({ last_feeling }) => last_feeling,
        //   say('Last time you were feeling {{ last_feeling }}')),
        run('cbt_feeling'),
        run('response1'),
        run('response2'),
        // run('summarise'),
        run('response3'),
        run('response4'),
        // run('summarise'),
        getRandom({ out: "responseloop1", p: 0.5 }),
        condition(function (_a) {
            var responseloop1 = _a.responseloop1;
            return responseloop1;
        }, run('responseloop1')),
        run('response5'),
        run('response6'),
        run('response7'),
        run('response8'),
        run('response9'),
        run('response10'),
        run('response11'),
        run('response12'),
        getRandom({ out: "response13", p: 0.5 }),
        condition(function (_a) {
            var response13 = _a.response13;
            return response13;
        }, run('response13')),
        run('response14'),
        run('response15'),
        run('response16'),
        run('response17'),
        run('response18'),
        run('response19'),
        getRandom({ out: "response20", p: 0.5 }),
        condition(function (_a) {
            var response20 = _a.response20;
            return response20;
        }, run('response20')),
        run('summarise'),
        run('bye'),
    ],
    cbt_feeling: [
        randomOption({ out: "cbt_feeling", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var cbt_feeling = _a.cbt_feeling;
            return cbt_feeling === "option1";
        }, say("How are you feeling just now?")),
        condition(function (_a) {
            var cbt_feeling = _a.cbt_feeling;
            return cbt_feeling === "option2";
        }, say("What's on your mind at the moment?")),
        condition(function (_a) {
            var cbt_feeling = _a.cbt_feeling;
            return cbt_feeling === "option3";
        }, say("How have you been feeling?")),
        condition(function (_a) {
            var cbt_feeling = _a.cbt_feeling;
            return cbt_feeling === "option4";
        }, say("How are you feeling just now?")),
        condition(function (_a) {
            var cbt_feeling = _a.cbt_feeling;
            return cbt_feeling === "option5";
        }, say("What's on your mind at the moment?")),
        condition(function (_a) {
            var cbt_feeling = _a.cbt_feeling;
            return cbt_feeling === "option6";
        }, say("what has been occupying your thoughts?")),
        condition(function (_a) {
            var cbt_feeling = _a.cbt_feeling;
            return cbt_feeling === "option7";
        }, say("What has been on your mind?")),
        ask('feeling'),
        rapid({ input: 'feeling', out: 'ranked_feeling' }),
        condition(function (context) { return context.ranked_feeling; }, // If we got a result from rapid
        toLowers({ input: 'ranked_feeling', out: 'feeling' }), // Run 'toSecondPerson' on it and overwrite the 'feeling' variable 
        toLowers({ input: 'feeling' })), // Otherwise transform the initial 'feeling' with 'toSecondPerson'.  This is a fallback in case rapid failed.
    ],
    response1: [
        randomOption({ out: "response1_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var response1_question = _a.response1_question;
            return response1_question === "option1";
        }, say("So when you say {{ feeling }}, say a bit more about that")),
        condition(function (_a) {
            var response1_question = _a.response1_question;
            return response1_question === "option2";
        }, say("So when you say {{ feeling }}, please share a bit more about that")),
        condition(function (_a) {
            var response1_question = _a.response1_question;
            return response1_question === "option3";
        }, say("So when you said {{ feeling }}, please say a bit more about why you are feeling that way")),
        condition(function (_a) {
            var response1_question = _a.response1_question;
            return response1_question === "option4";
        }, say("Thanks for sharing that, and so when you say {{ feeling }}, it would be good to expand on that and say a bit more about that")),
        condition(function (_a) {
            var response1_question = _a.response1_question;
            return response1_question === "option5";
        }, say("Thanks for sharing that, and when you say {{ feeling }}, please say a bit more about that")),
        condition(function (_a) {
            var response1_question = _a.response1_question;
            return response1_question === "option6";
        }, say("So that {{ feeling }} you just shared, please say a bit more about why you are feeling that way")),
        condition(function (_a) {
            var response1_question = _a.response1_question;
            return response1_question === "option7";
        }, say("So that {{ feeling }}, please say a bit more about why you are feeling that way")),
        ask('response1'),
    ],
    response2: [
        randomOption({ out: "response2_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var response2_question = _a.response2_question;
            return response2_question === "option1";
        }, say("And how did that make you feel?")),
        condition(function (_a) {
            var response2_question = _a.response2_question;
            return response2_question === "option2";
        }, say("What thoughts emerged for you when you reflect on those feelings?")),
        condition(function (_a) {
            var response2_question = _a.response2_question;
            return response2_question === "option3";
        }, say("What did you feel about that?")),
        condition(function (_a) {
            var response2_question = _a.response2_question;
            return response2_question === "option4";
        }, say("And when you reflect on that, what deeper feelings emerged for you?")),
        condition(function (_a) {
            var response2_question = _a.response2_question;
            return response2_question === "option5";
        }, say("And what other thoughts emerged for you when you reflect on those feelings?")),
        condition(function (_a) {
            var response2_question = _a.response2_question;
            return response2_question === "option6";
        }, say("And what do you think might have caused you to feel that way?")),
        condition(function (_a) {
            var response2_question = _a.response2_question;
            return response2_question === "option7";
        }, say("And what feelings emerged for you when you think about that as?")),
        ask('response2'),
    ],
    response3: [
        randomOption({ out: "response3_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var response3_question = _a.response3_question;
            return response3_question === "option1";
        }, say("And say a bit more about the circumstances that may have caused you to feel that way?")),
        condition(function (_a) {
            var response3_question = _a.response3_question;
            return response3_question === "option2";
        }, say("And say a bit more about the situation that may have caused you to feel that way?")),
        condition(function (_a) {
            var response3_question = _a.response3_question;
            return response3_question === "option3";
        }, say("And say a bit more about what has been happening that may have caused you to feel that way?")),
        condition(function (_a) {
            var response3_question = _a.response3_question;
            return response3_question === "option4";
        }, say("And what might have been causing you to feel that way?")),
        condition(function (_a) {
            var response3_question = _a.response3_question;
            return response3_question === "option5";
        }, say("And what are the circumstances that make you feel that way?")),
        condition(function (_a) {
            var response3_question = _a.response3_question;
            return response3_question === "option6";
        }, say("And what is the situation that may have made you feel that way?")),
        condition(function (_a) {
            var response3_question = _a.response3_question;
            return response3_question === "option7";
        }, say("And what has been happening that may have caused you to feel that way?")),
        ask('response3'),
        rapid({ input: 'response3', out: 'ranked_response3' }),
        condition(function (context) { return context.ranked_response3; }, // If we got a result from rapid
        toLowers({ input: 'ranked_response3', out: 'response3' }), // Run 'toSecondPerson' on it and overwrite the 'response7' variable 
        toLowers({ input: 'response3' })), // Otherwise transform the initial 'response7' with 'toSecondPerson'.  This is a fallback in case rapid failed.
    ],
    response4: [
        randomOption({ out: "response4_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var response4_question = _a.response4_question;
            return response4_question === "option1";
        }, say("So when you say {{ response3 }} about this situation, say a bit more about that")),
        condition(function (_a) {
            var response4_question = _a.response4_question;
            return response4_question === "option2";
        }, say("And say a bit more about the situation, when you say {{ response3 }} ")),
        condition(function (_a) {
            var response4_question = _a.response4_question;
            return response4_question === "option3";
        }, say("So you said {{ response3 }}, say a bit more about that")),
        condition(function (_a) {
            var response4_question = _a.response4_question;
            return response4_question === "option4";
        }, say("So when you say {{ response3 }} about these circumstances, say a bit more about that")),
        condition(function (_a) {
            var response4_question = _a.response4_question;
            return response4_question === "option5";
        }, say("And say a bit more about the situation, that might be causing you to feel like you do when you feel {{ response3 }} ")),
        condition(function (_a) {
            var response4_question = _a.response4_question;
            return response4_question === "option6";
        }, say("And say a bit more about the situation, that might be the cause of you feeling like you do when you feel {{ response3 }} ")),
        condition(function (_a) {
            var response4_question = _a.response4_question;
            return response4_question === "option7";
        }, say("So you said {{ response3 }}, say a bit more about that and go deeper into it")),
        ask('response4'),
    ],
    responseloop1: [
        randomOption({ out: "responseloop1_1_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var responseloop1_1_question = _a.responseloop1_1_question;
            return responseloop1_1_question === "option1";
        }, say("And what other factors in your life that may be causing you to feel this way?")),
        condition(function (_a) {
            var responseloop1_1_question = _a.responseloop1_1_question;
            return responseloop1_1_question === "option2";
        }, say("And what other things in your life just now that might be causing you to feel this way?")),
        condition(function (_a) {
            var responseloop1_1_question = _a.responseloop1_1_question;
            return responseloop1_1_question === "option3";
        }, say("And what other factors that might be making you feel this way?")),
        condition(function (_a) {
            var responseloop1_1_question = _a.responseloop1_1_question;
            return responseloop1_1_question === "option4";
        }, say("And what are some of the other factors in your life that may be causing you to feel this way?")),
        condition(function (_a) {
            var responseloop1_1_question = _a.responseloop1_1_question;
            return responseloop1_1_question === "option5";
        }, say("And what are some of the other things in your life just now that might be causing you to feel this way?")),
        condition(function (_a) {
            var responseloop1_1_question = _a.responseloop1_1_question;
            return responseloop1_1_question === "option6";
        }, say("And what are some of the other factors that might be making you feel this way?")),
        condition(function (_a) {
            var responseloop1_1_question = _a.responseloop1_1_question;
            return responseloop1_1_question === "option7";
        }, say("And what other issues are you experiencing that might be making you feel this way?")),
        ask('responseloop1_1'),
        randomOption({ out: "responseloop1_2_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var responseloop1_2_question = _a.responseloop1_2_question;
            return responseloop1_2_question === "option1";
        }, say("And how might these other factors be contributing to how you are feeling at the moment?")),
        condition(function (_a) {
            var responseloop1_2_question = _a.responseloop1_2_question;
            return responseloop1_2_question === "option2";
        }, say("And how might these other factors be contributing to how you are feeling just now")),
        condition(function (_a) {
            var responseloop1_2_question = _a.responseloop1_2_question;
            return responseloop1_2_question === "option3";
        }, say("And how might these other factors be influencing how you are feeling at the moment?")),
        condition(function (_a) {
            var responseloop1_2_question = _a.responseloop1_2_question;
            return responseloop1_2_question === "option4";
        }, say("And how might these other issues be contributing to how you are feeling at the moment?")),
        condition(function (_a) {
            var responseloop1_2_question = _a.responseloop1_2_question;
            return responseloop1_2_question === "option5";
        }, say("And how might these other things be contributing to how you are feeling just now")),
        condition(function (_a) {
            var responseloop1_2_question = _a.responseloop1_2_question;
            return responseloop1_2_question === "option6";
        }, say("And how might these other issues be influencing how you are feeling at the moment?")),
        condition(function (_a) {
            var responseloop1_2_question = _a.responseloop1_2_question;
            return responseloop1_2_question === "option7";
        }, say("And how might these other things be affecting how you are feeling at the moment?")),
        ask('responseloop1_2'),
        randomOption({ out: "responseloop1_3_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var responseloop1_3_question = _a.responseloop1_3_question;
            return responseloop1_3_question === "option1";
        }, say("And how might these other factors be connected to what you described earlier?")),
        condition(function (_a) {
            var responseloop1_3_question = _a.responseloop1_3_question;
            return responseloop1_3_question === "option2";
        }, say("And how might those other factors be related to what you initially described?")),
        condition(function (_a) {
            var responseloop1_3_question = _a.responseloop1_3_question;
            return responseloop1_3_question === "option3";
        }, say("And what might be the connection between those other factors and what you described earlier?")),
        condition(function (_a) {
            var responseloop1_3_question = _a.responseloop1_3_question;
            return responseloop1_3_question === "option4";
        }, say("And how might these other things be connected to what you described earlier?")),
        condition(function (_a) {
            var responseloop1_3_question = _a.responseloop1_3_question;
            return responseloop1_3_question === "option5";
        }, say("And how might those other issues be related to what you initially described?")),
        condition(function (_a) {
            var responseloop1_3_question = _a.responseloop1_3_question;
            return responseloop1_3_question === "option6";
        }, say("And what might be the connection between those other things and what you described earlier?")),
        condition(function (_a) {
            var responseloop1_3_question = _a.responseloop1_3_question;
            return responseloop1_3_question === "option7";
        }, say("And what might be the connection between those other issues and what you described earlier?")),
        ask('responseloop1_3'),
    ],
    response5: [
        randomOption({ out: "response5_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var response5_question = _a.response5_question;
            return response5_question === "option1";
        }, say("And what might you need to resolve any concerns you have about how you feel?")),
        condition(function (_a) {
            var response5_question = _a.response5_question;
            return response5_question === "option2";
        }, say("And what might you need to do so you can resolve any concerns you have about how you feel?")),
        condition(function (_a) {
            var response5_question = _a.response5_question;
            return response5_question === "option3";
        }, say("And what might you need to resolve any concerns you have about how you are feeling just now?")),
        condition(function (_a) {
            var response5_question = _a.response5_question;
            return response5_question === "option4";
        }, say("And what might you need to deal with any concerns you have about how you feel?")),
        condition(function (_a) {
            var response5_question = _a.response5_question;
            return response5_question === "option5";
        }, say("And what might you need to do so you can deal with any concerns you have about how you feel?")),
        condition(function (_a) {
            var response5_question = _a.response5_question;
            return response5_question === "option6";
        }, say("And what might you need to deal with any concerns you have about how you are feeling just now?")),
        condition(function (_a) {
            var response5_question = _a.response5_question;
            return response5_question === "option7";
        }, say("And what might you need to work through any concerns you have about how you are feeling just now?")),
        ask('response5'),
        rapid({ input: 'response5', out: 'ranked_response5' }),
        condition(function (context) { return context.ranked_response5; }, // If we got a result from rapid
        toLowers({ input: 'ranked_response5', out: 'response5' }), // Run 'toSecondPerson' on it and overwrite the 'response7' variable 
        toLowers({ input: 'response5' })), // Otherwise transform the initial 'response7' with 'toSecondPerson'.  This is a fallback in case rapid failed.
    ],
    response6: [
        randomOption({ out: "response6_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var response6_question = _a.response6_question;
            return response6_question === "option1";
        }, say("So when you say {{ response5 }} about this situation, say a bit more about that")),
        condition(function (_a) {
            var response6_question = _a.response6_question;
            return response6_question === "option2";
        }, say("And say a bit more about the situation, when you say {{ response5 }} ")),
        condition(function (_a) {
            var response6_question = _a.response6_question;
            return response6_question === "option3";
        }, say("So you said {{ response5 }}, say a bit more about that")),
        condition(function (_a) {
            var response6_question = _a.response6_question;
            return response6_question === "option4";
        }, say("So when you say {{ response5 }} about these circumstances, say a bit more about that")),
        condition(function (_a) {
            var response6_question = _a.response6_question;
            return response6_question === "option5";
        }, say("And say a bit more about the these circumstances, when you say {{ response5 }} ")),
        condition(function (_a) {
            var response6_question = _a.response6_question;
            return response6_question === "option6";
        }, say("So you said {{ response5 }}, go deeper and say a bit more about that")),
        condition(function (_a) {
            var response6_question = _a.response6_question;
            return response6_question === "option7";
        }, say("So you said {{ response5 }}, go a bit further and say more about that")),
        ask('response6'),
    ],
    response7: [
        randomOption({ out: "response7_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var response7_question = _a.response7_question;
            return response7_question === "option1";
        }, say("And what would you like the most positive outcome to be for you?")),
        condition(function (_a) {
            var response7_question = _a.response7_question;
            return response7_question === "option2";
        }, say("And what do you think would be the best outcome for you?")),
        condition(function (_a) {
            var response7_question = _a.response7_question;
            return response7_question === "option3";
        }, say("And what would your preferred outcome be?")),
        condition(function (_a) {
            var response7_question = _a.response7_question;
            return response7_question === "option4";
        }, say("And what would be the most positive outcome for you?")),
        condition(function (_a) {
            var response7_question = _a.response7_question;
            return response7_question === "option5";
        }, say("And what do you feel would be the best outcome for you?")),
        condition(function (_a) {
            var response7_question = _a.response7_question;
            return response7_question === "option6";
        }, say("And because you can choose, what would your preferred outcome be?")),
        condition(function (_a) {
            var response7_question = _a.response7_question;
            return response7_question === "option7";
        }, say("And b,ecause you have the power to choose, what would your preferred outcome be?")),
        ask('response7'),
    ],
    response8: [
        randomOption({ out: "response8_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var response8_question = _a.response8_question;
            return response8_question === "option1";
        }, say("And what might prevent you from reaching your preferred outcome?")),
        condition(function (_a) {
            var response8_question = _a.response8_question;
            return response8_question === "option2";
        }, say("And what challenges do you feel you face in achieving what you need?")),
        condition(function (_a) {
            var response8_question = _a.response8_question;
            return response8_question === "option3";
        }, say("And what obstacles do you feel might stop you from reaching your best outcome?")),
        condition(function (_a) {
            var response8_question = _a.response8_question;
            return response8_question === "option4";
        }, say("And what might stop you from reaching your preferred outcome?")),
        condition(function (_a) {
            var response8_question = _a.response8_question;
            return response8_question === "option5";
        }, say("And what challenges might you encounter in achieving what you need?")),
        condition(function (_a) {
            var response8_question = _a.response8_question;
            return response8_question === "option6";
        }, say("And what obstacles might you encounter that could stop you from reaching your best outcome?")),
        condition(function (_a) {
            var response8_question = _a.response8_question;
            return response8_question === "option7";
        }, say("And what challenges do you feel might stop you from reaching your best outcome?")),
        ask('response8'),
    ],
    response9: [
        randomOption({ out: "response9_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var response9_question = _a.response9_question;
            return response9_question === "option1";
        }, say("And how might you overcome those challenges as you move towards action?")),
        condition(function (_a) {
            var response9_question = _a.response9_question;
            return response9_question === "option2";
        }, say("And what might be your strategy to overcome those challenges?")),
        condition(function (_a) {
            var response9_question = _a.response9_question;
            return response9_question === "option3";
        }, say("And how will you actively engage with those challenges and overcome them?")),
        condition(function (_a) {
            var response9_question = _a.response9_question;
            return response9_question === "option4";
        }, say("And how might you deal with those challenges as you move towards action?")),
        condition(function (_a) {
            var response9_question = _a.response9_question;
            return response9_question === "option5";
        }, say("And what might be the steps you can take to overcome those challenges?")),
        condition(function (_a) {
            var response9_question = _a.response9_question;
            return response9_question === "option6";
        }, say("And what might be the steps you can take to deal with those challenges")),
        condition(function (_a) {
            var response9_question = _a.response9_question;
            return response9_question === "option7";
        }, say("And how will you positively engage with those challenges and overcome them?")),
        ask('response9'),
        rapid({ input: 'response9', out: 'ranked_response9' }),
        condition(function (context) { return context.ranked_response9; }, // If we got a result from rapid
        toLowers({ input: 'ranked_response9', out: 'response9' }), // Run 'toSecondPerson' on it and overwrite the 'response11' variable 
        toLowers({ input: 'response9' })), // Otherwise transform the initial 'response11' with 'toSecondPerson'.  This is a fallback in case rapid failed.
    ],
    response10: [
        randomOption({ out: "response10_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var response10_question = _a.response10_question;
            return response10_question === "option1";
        }, say("So when you think about overcoming those challenges, when you mentioned {{ response9 }}, say a bit more about that")),
        condition(function (_a) {
            var response10_question = _a.response10_question;
            return response10_question === "option2";
        }, say("And so when you mentioned {{ response9 }} in overcoming those challenges, say more about that")),
        condition(function (_a) {
            var response10_question = _a.response10_question;
            return response10_question === "option3";
        }, say("And so when you mentioned {{ response9 }} in dealing with those challenges, say more about that")),
        condition(function (_a) {
            var response10_question = _a.response10_question;
            return response10_question === "option4";
        }, say("So when you think about dealing with those challenges, when you mentioned {{ response9 }}, say a bit more about that")),
        condition(function (_a) {
            var response10_question = _a.response10_question;
            return response10_question === "option5";
        }, say("And so when you mentioned {{ response9 }} in working through those challenges, say more about that")),
        condition(function (_a) {
            var response10_question = _a.response10_question;
            return response10_question === "option6";
        }, say("And so when you mentioned {{ response9 }} in actively engaging with those challenges, say more about that")),
        condition(function (_a) {
            var response10_question = _a.response10_question;
            return response10_question === "option7";
        }, say("And so when you mentioned {{ response9 }} in actively dealing with those challenges, say more about that")),
        ask('response10'),
    ],
    response11: [
        randomOption({ out: "response11_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var response11_question = _a.response11_question;
            return response11_question === "option1";
        }, say("And of those challenges, which one will be the most satisfying to overcome for you?")),
        condition(function (_a) {
            var response11_question = _a.response11_question;
            return response11_question === "option2";
        }, say("And what will be the most satisfying challenge to overcome for you?")),
        condition(function (_a) {
            var response11_question = _a.response11_question;
            return response11_question === "option3";
        }, say("And which challenge will be the most satisfying for you, when you overcome it?")),
        condition(function (_a) {
            var response11_question = _a.response11_question;
            return response11_question === "option4";
        }, say("And of those potential obstacles in your way, which one will be the most satisfying to overcome for you?")),
        condition(function (_a) {
            var response11_question = _a.response11_question;
            return response11_question === "option5";
        }, say("And what will be the most satisfying issue to overcome for you?")),
        condition(function (_a) {
            var response11_question = _a.response11_question;
            return response11_question === "option6";
        }, say("And dealing with which of these potential obstacles will be the most satisfying for you, when you overcome it?")),
        condition(function (_a) {
            var response11_question = _a.response11_question;
            return response11_question === "option7";
        }, say("And which issue will be the most satisfying for you, when you overcome it?")),
        ask('response11'),
    ],
    response12: [
        randomOption({ out: "response12_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var response12_question = _a.response12_question;
            return response12_question === "option1";
        }, say("And what resources do you feel you will need to successfully make that happen?")),
        condition(function (_a) {
            var response12_question = _a.response12_question;
            return response12_question === "option2";
        }, say("And what resources will you require for your success?")),
        condition(function (_a) {
            var response12_question = _a.response12_question;
            return response12_question === "option3";
        }, say("And what resources will you need to successfully make that happen?")),
        condition(function (_a) {
            var response12_question = _a.response12_question;
            return response12_question === "option4";
        }, say("And what resources do you think you will need to successfully make that happen?")),
        condition(function (_a) {
            var response12_question = _a.response12_question;
            return response12_question === "option5";
        }, say("And what resources will you need for your success?")),
        condition(function (_a) {
            var response12_question = _a.response12_question;
            return response12_question === "option6";
        }, say("And what resources will you require to successfully make that happen?")),
        condition(function (_a) {
            var response12_question = _a.response12_question;
            return response12_question === "option7";
        }, say("And what resources might you need to successfully make that happen?")),
        ask('response12'),
    ],
    // response13 is probabilistic
    response13: [
        randomOption({ out: "response13_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var response13_question = _a.response13_question;
            return response13_question === "option1";
        }, say("Who can you ask for help to make this happen for you?")),
        condition(function (_a) {
            var response13_question = _a.response13_question;
            return response13_question === "option2";
        }, say("Who can help to make this happen for you?")),
        condition(function (_a) {
            var response13_question = _a.response13_question;
            return response13_question === "option3";
        }, say("Who can help you to make your way through this challenge?")),
        condition(function (_a) {
            var response13_question = _a.response13_question;
            return response13_question === "option4";
        }, say("Who might you ask for help to make this happen for you?")),
        condition(function (_a) {
            var response13_question = _a.response13_question;
            return response13_question === "option5";
        }, say("Who might help to make this happen for you?")),
        condition(function (_a) {
            var response13_question = _a.response13_question;
            return response13_question === "option6";
        }, say("Who might help you to make your way through this challenge?")),
        condition(function (_a) {
            var response13_question = _a.response13_question;
            return response13_question === "option7";
        }, say("Who might be able to help you to make your way through this challenge?")),
        ask('response13'),
    ],
    response14: [
        randomOption({ out: "response14_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var response14_question = _a.response14_question;
            return response14_question === "option1";
        }, say("And how might you overcome those challenges as you move towards action?")),
        condition(function (_a) {
            var response14_question = _a.response14_question;
            return response14_question === "option2";
        }, say("And what might be your strategy to overcome those challenges?")),
        condition(function (_a) {
            var response14_question = _a.response14_question;
            return response14_question === "option3";
        }, say("And how will you actively engage with those challenges and overcome them?")),
        condition(function (_a) {
            var response14_question = _a.response14_question;
            return response14_question === "option4";
        }, say("And how might you deal with those challenges as you move towards action?")),
        condition(function (_a) {
            var response14_question = _a.response14_question;
            return response14_question === "option5";
        }, say("And what might be your strategy to deal with those challenges?")),
        condition(function (_a) {
            var response14_question = _a.response14_question;
            return response14_question === "option6";
        }, say("And how will you actively deal with those challenges and overcome them?")),
        condition(function (_a) {
            var response14_question = _a.response14_question;
            return response14_question === "option7";
        }, say("And how will you positively engage with those challenges and overcome them?")),
        ask('response14'),
        rapid({ input: 'response14', out: 'ranked_response14' }),
        condition(function (context) { return context.ranked_response14; }, // If we got a result from rapid
        toLowers({ input: 'ranked_response14', out: 'response14' }), // Run 'toSecondPerson' on it and overwrite the 'response11' variable 
        toLowers({ input: 'response14' })), // Otherwise transform the initial 'response11' with 'toSecondPerson'.  This is a fallback in case rapid failed.
    ],
    response15: [
        randomOption({ out: "response15_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var response15_question = _a.response15_question;
            return response15_question === "option1";
        }, say("So when you think about overcoming those challenges, when you mentioned {{ response14 }}, say a bit more about that")),
        condition(function (_a) {
            var response15_question = _a.response15_question;
            return response15_question === "option2";
        }, say("And so when you mentioned {{ response14 }} in overcoming those challenges, say more about that")),
        condition(function (_a) {
            var response15_question = _a.response15_question;
            return response15_question === "option3";
        }, say("And so when you mentioned {{ response14 }} in dealing with those challenges, say more about that")),
        condition(function (_a) {
            var response15_question = _a.response15_question;
            return response15_question === "option4";
        }, say("So when you think about dealing with those challenges, when you mentioned {{ response14 }}, say a bit more about that")),
        condition(function (_a) {
            var response15_question = _a.response15_question;
            return response15_question === "option5";
        }, say("And so when you mentioned {{ response14 }} in working through those challenges, say more about that")),
        condition(function (_a) {
            var response15_question = _a.response15_question;
            return response15_question === "option6";
        }, say("And so when you mentioned {{ response14 }} in dealing with those obstacles, say more about that")),
        condition(function (_a) {
            var response15_question = _a.response15_question;
            return response15_question === "option7";
        }, say("And so when you mentioned {{ response14 }} in working through those obstacles, say more about that")),
        ask('response15'),
    ],
    response16: [
        randomOption({ out: "response16_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var response16_question = _a.response16_question;
            return response16_question === "option1";
        }, say("And how do you think you can actually make that happen?")),
        condition(function (_a) {
            var response16_question = _a.response16_question;
            return response16_question === "option2";
        }, say("And what are the actual steps can you take to make that actually happen?")),
        condition(function (_a) {
            var response16_question = _a.response16_question;
            return response16_question === "option3";
        }, say("And what would you need to do for that to happen?")),
        condition(function (_a) {
            var response16_question = _a.response16_question;
            return response16_question === "option4";
        }, say("And what are some of the ways you can actually make that happen?")),
        condition(function (_a) {
            var response16_question = _a.response16_question;
            return response16_question === "option5";
        }, say("And what are the positive steps can you take to make that actually happen?")),
        condition(function (_a) {
            var response16_question = _a.response16_question;
            return response16_question === "option6";
        }, say("And what actions would you need to take for that to happen?")),
        condition(function (_a) {
            var response16_question = _a.response16_question;
            return response16_question === "option7";
        }, say("And what are the positive actions that you would you need to take for that to happen?")),
        ask('response16'),
    ],
    response17: [
        randomOption({ out: "response17_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var response17_question = _a.response17_question;
            return response17_question === "option1";
        }, say("And how will you know you have made that actually happen?")),
        condition(function (_a) {
            var response17_question = _a.response17_question;
            return response17_question === "option2";
        }, say("And what will be the sign that you have made that happen?")),
        condition(function (_a) {
            var response17_question = _a.response17_question;
            return response17_question === "option3";
        }, say("And how you will realise that you have made that happen?")),
        condition(function (_a) {
            var response17_question = _a.response17_question;
            return response17_question === "option4";
        }, say("And how will you know you have made your chosen outcome actually happen?")),
        condition(function (_a) {
            var response17_question = _a.response17_question;
            return response17_question === "option5";
        }, say("And what will be the sign that you have made your chosen outcome happen?")),
        condition(function (_a) {
            var response17_question = _a.response17_question;
            return response17_question === "option6";
        }, say("And how you will realise that you have actually made that happen?")),
        condition(function (_a) {
            var response17_question = _a.response17_question;
            return response17_question === "option7";
        }, say("And how you will know that you have actually made that happen?")),
        ask('response17'),
        rapid({ input: 'response17', out: 'ranked_response17' }),
        condition(function (context) { return context.ranked_response17; }, // If we got a result from rapid
        toLowers({ input: 'ranked_response17', out: 'response17' }), // Run 'toSecondPerson' on it and overwrite the 'response7' variable 
        toLowers({ input: 'response17' })), // Otherwise transform the initial 'response7' with 'toSecondPerson'.  This is a fallback in case rapid failed.
    ],
    response18: [
        randomOption({ out: "response18_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var response18_question = _a.response18_question;
            return response18_question === "option1";
        }, say("So when you say {{ response17 }} about making that happen say a bit more about that")),
        condition(function (_a) {
            var response18_question = _a.response18_question;
            return response18_question === "option2";
        }, say("And so say a bit more about how you might make that happen, when you say {{ response17 }} ")),
        condition(function (_a) {
            var response18_question = _a.response18_question;
            return response18_question === "option3";
        }, say("So you said {{ response17 }} would help make that happen, say a bit more about that")),
        condition(function (_a) {
            var response18_question = _a.response18_question;
            return response18_question === "option4";
        }, say("So when you say {{ response17 }} about making your chosen outcome happen, say a bit more about that")),
        condition(function (_a) {
            var response18_question = _a.response18_question;
            return response18_question === "option5";
        }, say("And so say a bit more about how you can actually make that happen, when you say {{ response17 }} ")),
        condition(function (_a) {
            var response18_question = _a.response18_question;
            return response18_question === "option6";
        }, say("So you said {{ response17 }} would help you to make that happen, say a bit more about that")),
        condition(function (_a) {
            var response18_question = _a.response18_question;
            return response18_question === "option7";
        }, say("So you said {{ response17 }} would help you to make your chosen outcome happen, say a bit more about that")),
        ask('response18'),
    ],
    response19: [
        randomOption({ out: "response19_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var response19_question = _a.response19_question;
            return response19_question === "option1";
        }, say("And what do you think the outcome of those actions might be in the future?")),
        condition(function (_a) {
            var response19_question = _a.response19_question;
            return response19_question === "option2";
        }, say("And how do see the outcome of your actions unfolding in the future?")),
        condition(function (_a) {
            var response19_question = _a.response19_question;
            return response19_question === "option3";
        }, say("And what does the future look like if you take those actions?")),
        condition(function (_a) {
            var response19_question = _a.response19_question;
            return response19_question === "option4";
        }, say("And what do you think the outcome of those actions might be for you in the future?")),
        condition(function (_a) {
            var response19_question = _a.response19_question;
            return response19_question === "option5";
        }, say("And how do see the outcome of your actions emerging in the future?")),
        condition(function (_a) {
            var response19_question = _a.response19_question;
            return response19_question === "option6";
        }, say("And what does the future look like for you when you take those actions?")),
        condition(function (_a) {
            var response19_question = _a.response19_question;
            return response19_question === "option7";
        }, say("And what does the future look like for you if you take those actions?")),
        ask('response19'),
    ],
    response20: [
        randomOption({ out: "response20_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var response20_question = _a.response20_question;
            return response20_question === "option1";
        }, say("And what do you feel are some of the more positive aspects in your life at the moment?")),
        condition(function (_a) {
            var response20_question = _a.response20_question;
            return response20_question === "option2";
        }, say("And what do you feel are some of the more supportive aspects in your life at the moment?")),
        condition(function (_a) {
            var response20_question = _a.response20_question;
            return response20_question === "option3";
        }, say("And what do you feel are some of the more helpful aspects in your life at the moment?")),
        condition(function (_a) {
            var response20_question = _a.response20_question;
            return response20_question === "option4";
        }, say("And what do you feel are some of the more positive and helpful aspects in your life at the moment?")),
        condition(function (_a) {
            var response20_question = _a.response20_question;
            return response20_question === "option5";
        }, say("And what do you feel are some of the more positive and supportive aspects in your life at the moment?")),
        condition(function (_a) {
            var response20_question = _a.response20_question;
            return response20_question === "option6";
        }, say("And what do you feel are some of the more helpful and supportive aspects in your life at the moment?")),
        condition(function (_a) {
            var response20_question = _a.response20_question;
            return response20_question === "option7";
        }, say("And what do you feel are some of the healthier aspects in your life at the moment?")),
        ask('response20'),
    ],
    summarise: [
        // say('Hello, can you describe an image?'),
        // ask('response_11'),
        // say('Hello, can you describe another image?'),
        // ask('response_12'),
        summarise({ inputs: ['response1', 'response2', 'response11', 'response12', 'response3', 'response4'], out: "summary_text" }),
        say('So what we have discussed is {{summary_text}}'),
    ],
    bye: [
        randomOption({ out: "bye", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var bye = _a.bye;
            return bye === "option1";
        }, say("Thanks for sharing your feelings with me just now, bye for now.")),
        condition(function (_a) {
            var bye = _a.bye;
            return bye === "option2";
        }, say("Thanks for that conversation, I hope you found it useful. Bye for now.")),
        condition(function (_a) {
            var bye = _a.bye;
            return bye === "option3";
        }, say("Thanks for that conversation, I hope you found it of value. Goodbye, until the next time.")),
        condition(function (_a) {
            var bye = _a.bye;
            return bye === "option4";
        }, say("Thanks for sharing your feelings with me just now, bye for now.")),
        condition(function (_a) {
            var bye = _a.bye;
            return bye === "option5";
        }, say("Thanks for sharing how you have been feeling, I hope you found it useful. Bye for now.")),
        condition(function (_a) {
            var bye = _a.bye;
            return bye === "option6";
        }, say("Thank you for sharing your thoughts and feelings, I hope you found it of value. Bye, until the next time.")),
        condition(function (_a) {
            var bye = _a.bye;
            return bye === "option7";
        }, say("Thanks for that conversation, I hope you found it of value. Goodbye, until the next time.")),
        ask('bye'),
    ],
};
