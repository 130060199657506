var caretsian = function (arr1, arr2) {
    var result = [];
    for (var _i = 0, arr1_1 = arr1; _i < arr1_1.length; _i++) {
        var item1 = arr1_1[_i];
        for (var _a = 0, arr2_1 = arr2; _a < arr2_1.length; _a++) {
            var item2 = arr2_1[_a];
            result.push([item1, item2]);
        }
    }
    return result;
};
var questionPrepositions = ['How', 'Where', 'When', 'What'];
var questionModals = ['might you', 'might you be able', 'could you', 'would you', 'should you'];
var questionPrefixes = caretsian(questionPrepositions, questionModals).map(function (arr) { return arr.join(' '); });
var suffixedQuestionPrefixes = function (suffix) { return questionPrefixes.map(function (prefix) { return "".concat(prefix, " ").concat(suffix); }); };
export var imageQuestionConjunctionSplitters = ['by', 'rather', 'instead', 'so', 'even though', 'while'];
export var questionSuffixes = ['in this situation', 'in what you are describing', 'in what you have said', 'in what you have been saying'];
// Example definitions
// an ability to count on some of your old-fashioned talents so you can piece the facts together and calculate the eventual outcome
// an apparent ability to harness your unconscious energies so you can balance them with your obvious humanity as a way of motivating myself
// an apparent opportunity to reconnect with a number of different aspects of your character that you thought you had managed to lay to rest
// an aspect of your character that can maintain your self-image and hold it all together in the face of any criticism from other people
// an aspect of your character who has the power and authority to take immediate action instead of just always living in the past
// an aspect of your character whose refinement is widely recognised by other people and that has the maturity to use it graciously
// an aspect of your professional identity that enables you to understand the area where you can employ your talents most productively
// an aspect of yourself that you tend to keep hidden away from other people in case they might see you unfavourably in a different light
// aspects of your character that have the organised power to defend your basic needs and resolve your self-destructive behaviours
// an elemental ability to clear up any unseen challenges by taking a positive and healthy approach to how you express your talents
// an essential ability to feel really connected to your strongest core instincts even though they can sometimes make you feel vulnerable
// an essential awareness that gives you the individual ability to take independent action so that you can understand who you really am
// an essential capacity to convey your unique passion and vitality in any situation by naturally channelling the flow of your emotions
// an essential opportunity to use your gut instincts to confront an uncomfortable situation so that you can satisfactorily resolve it
// an essential realisation that you can motivate yourself by using your instinctive behavioural patterns to put your feelings into action
// an essential understanding that the best way to attract people is to have faith in your own uniqueness so you don't betray your talents
// an instinctive ability that can bring vital new energy to a plan that is very close to your heart so that you can happily develop it
// an instinctive ability that embodies all aspects of your creative nature and gives you the motivation to express your natural wisdom
// an instinctive ability to reveal a deeper understanding of your purpose by persistently delving into the vital aspects of your plans
// an instinctive awareness that helps you to deal with any unpleasant thoughts rather than just leaving a bitter taste in your mouth
// an instinctive capacity to be kind to people by regularly meeting their emotional needs without causing yourself too much tension
// an instinctive opportunity to powerfully assert your own creativity rather than being afraid that it will make you appear weak
// a mood that can colour your perspective by making you feel cautious about becoming completely stuck in some old ways of working
// a mood that can shift your perspective by reflecting the emotional intensity of a situation and how it is illuminating your feelings
// a natural ability for a seemingly insignificant story to potentially grow into something much stronger and more colourful
// a natural ability for developing your longer term spiritual growth while staying rooted in the practicalities of your deeper experience
// a natural ability for your optimism to bloom by cheerfully opening up to your creative plans and allowing them to fully blossom
// a natural ability to take the small seed of an idea and develop its huge potential for growth while staying rooted in reality
// a natural awareness that you are constantly surrounded by your ideas but only really notice them when you meet with any resistance
// a natural capacity to use all your available power to keep yourself fully motivated so that you can always achieve your chosen outcome
// a natural opportunity to open up to some blossoming emotions without becoming too absorbed and letting them grow out of control
// a natural potential for your longer term spiritual growth by sharpening your awareness so that you can always reflect on your memories
// a natural potential to display your passion for your longer term spiritual growth while staying rooted in everyday practicalities
// a natural progression where you can have a more balanced awareness of the conscious and unconscious aspects of your situation
// an opportunity for a vital transformation that can illuminate your fundamental passions and heighten your developing creativity
// an opportunity for you to make a transformative decision by understanding the grounds for any fundamentally opposing viewpoints
// an opportunity to rediscover a part of yourself that you have been neglecting so you can reconnect with all the potential that it holds
// a potential ability to go through the motions of trying to fulfil an ambition without gaining any real sense of achievement
// a potential opportunity to healthily fulfil an ambition by being able to digest some of the less savoury aspects of an experience
// a potential to fulfil an emotional need by satisfying your thirst for experience even though it may be clouding your judgement
// a realisation about your potential for personal transformation by understanding how you can begin to change your immediate surroundings
// a realisation of the wealth of talent that you can offer to other people instead of constantly undervaluing your unique abilities
// a realisation you can state your chosen preferences in confident way instead of constantly looking for approval from other people
// a realisation that you can make a worthy contribution to a situation rather than feeling ashamed about your ability to participate
// a realisation that you understand the fundamental practicalities and can take definite action rather than just being too theoretical
// a situation that can lead to a variety of new choices and can help you to explore the inner and outer aspects of who you really am
// a situation where you are getting close to an understanding but are still progressing towards a more fully connected awareness
// a situation where you can become more aware of how to use your unique characteristics to resolve a challenge that has been occupying you
// a situation where you feel most at home and comfortable with your ideas and can take a relaxed approach to exploring your thoughts
// a situation where you give yourself the power and permission to choose who you want to be with instead of leaving it up to other people
// a situation where you have to walk a fine line when making a big commitment so you can ensure that you stay on the straight and narrow
// a situation where you have been disregarding your wider social connections even though they may hold great potential value for you
export var imageQuestionPrefixMappings = [
    {
        startsWith: 'an ability to',
        values: questionPrefixes,
        imageTherapyPrefixes: {
            reflecting: ['might you not be able', 'might you be unable'],
            expanding: ['might you explore other options', 'might you consider other choices'],
            acting: ['will you take action to', 'will you use your abilities to'],
        }
    },
    {
        startsWith: 'an apparent ability to',
        values: questionPrefixes,
        imageTherapyPrefixes: {
            reflecting: ['might you not be able', 'might you be unable'],
            expanding: ['might you explore other options', 'might you consider other choices'],
            acting: ['will you take action to', 'will you use your abilities to'],
        }
    },
    {
        startsWith: 'an apparent opportunity to',
        values: questionPrefixes,
        imageTherapyPrefixes: {
            reflecting: ['might you have less opportunity', 'have little opportunity'],
            expanding: ['might you explore other opportunities', 'might you consider different opportunities'],
            acting: ['will you make the most of this opportunity', 'will you use this opportunity'],
        }
    },
    {
        startsWith: 'an aspect of your character that',
        values: ['What part of you', 'What part of your character', 'What skill do you possess that', 'Which of your abilities'],
        imageTherapyPrefixes: {
            reflecting: ['What ability do you need that', 'What characteristic do yo need that'],
            expanding: ['What other option do you have that', 'What other choice do you have that'],
            acting: ['What action can you take that', 'What step can you take that'],
        }
    },
    {
        startsWith: 'an aspect of your character who',
        values: ['What part of you', 'What part of your character', 'What skill do you possess that', 'Which of your abilities'],
        imageTherapyPrefixes: {
            reflecting: ['What ability do you need that', 'What characteristic do yo need that'],
            expanding: ['What other option do you have that', 'What other choice do you have that'],
            acting: ['What action can you take that', 'What step can you take that'],
        }
    },
    {
        startsWith: 'an aspect of your character whose',
        values: ['What part of your'],
        imageTherapyPrefixes: {
            reflecting: ['might you not be able', 'might you be unable'],
            expanding: [''],
            acting: [''],
        }
    },
    {
        startsWith: 'an aspect of your professional identity that',
        values: ['What part of you', 'What part of your character', 'What skill do you possess that', 'Which of your abilities'],
        imageTherapyPrefixes: {
            reflecting: ['What ability do you need that', 'What characteristic do yo need that'],
            expanding: ['What other option do you have that', 'What other choice do you have that'],
            acting: ['What action can you take that', 'What step can you take that'],
        }
    },
    {
        startsWith: 'an aspect of yourself that',
        values: ['What part of yourself do', 'Which of your skills do', 'Which of your talents do'],
        imageTherapyPrefixes: {
            reflecting: ['What challenges do you face with what', 'What obstacles do you face with what'],
            expanding: ['What other opportunities do', 'What other potential choices do'],
            acting: ['What actions will you now take with what', 'What steps can you now take with what'],
        }
    },
    {
        startsWith: 'aspects of your character that',
        values: ['What parts of you', 'What actions do you take that'],
        imageTherapyPrefixes: {
            reflecting: ['Where do you not', 'When might you not'],
            expanding: ['Which of your other choices might', 'Which of your other options may have'],
            acting: ['What actions can youtake that will', 'What steps can you take that will'],
        }
    },
    {
        startsWith: 'an elemental ability',
        values: ['Where might you be able', 'How might you be able'],
        imageTherapyPrefixes: {
            reflecting: ['Where might you not be able', 'Where might you be unable'],
            expanding: ['What other options might you have', 'What other choices might you have'],
            acting: ['What specific actions will you take', 'What actions will you take'],
        }
    },
    {
        startsWith: 'an essential ability to',
        values: ['Where might you', 'Where might you be able to'],
        imageTherapyPrefixes: {
            reflecting: ['Where might you not be able to', 'Where might you be unable to'],
            expanding: ['What other options might you have to', 'What other choices might you have to'],
            acting: ['What specific actions will you take to', 'What actions will you take to'],
        }
    },
    {
        startsWith: 'an essential awareness of',
        values: ['How might you become more aware of', 'Where might you become more aware of'],
        imageTherapyPrefixes: {
            reflecting: ['Where might you not be aware of', 'When might you be unaware of'],
            expanding: ['What else', 'How might you increase your understanding of what'],
            acting: ['What specific action can you take that', 'What steps will you now take that'],
        }
    },
    {
        startsWith: 'an essential awareness that',
        values: ['What do you feel', 'What do you think it is that'],
        imageTherapyPrefixes: {
            reflecting: ['What don\'t you feel', 'What don\'t you think'],
            expanding: ['What other option', 'What other choice'],
            acting: ['what specific action can you take that', 'What specific choice can you make that'],
        }
    },
    {
        startsWith: 'an essential capacity to',
        values: questionPrefixes,
        imageTherapyPrefixes: {
            reflecting: ['might you not be able to', 'might you be unable to'],
            expanding: ['What other options do you have to', 'What different choces do you have to'],
            acting: ['What specific actions can you take to', 'What actions will you now take to'],
        }
    },
    {
        startsWith: 'an essential opportunity to',
        values: questionPrefixes,
        imageTherapyPrefixes: {
            reflecting: ['might you not be able to', 'might you be unable to'],
            expanding: ['What other options do you have to', 'What different choces do you have to'],
            acting: ['What specific actions will you take to', 'What actions will you now take to'],
        }
    },
    {
        startsWith: 'an essential realisation that you can',
        values: questionPrefixes,
        imageTherapyPrefixes: {
            reflecting: ['might you not be unware', 'might you not realise'],
            expanding: [''],
            acting: [''],
        }
    },
    {
        startsWith: 'an essential understanding that',
        values: ['Where might you understand that', 'Where might you realise that'],
        imageTherapyPrefixes: {
            reflecting: ['Where might you be unaware that', 'Where might you not realise that'],
            expanding: ['Where else might you realise that', 'How else might you become aware that'],
            acting: ['What actions can yo now take to show that', 'What steps will you now take to demonstrate that'],
        }
    },
    {
        startsWith: 'an instinctive ability that can',
        values: ['What do you feel can', 'What do you think can'],
        imageTherapyPrefixes: {
            reflecting: ['What do you think might not', 'What do you feel cannot'],
            expanding: ['What other options do yo have that', 'What other choices do yo have that'],
            acting: ['What actions can you now take that', 'What steps will you now take that'],
        }
    },
    {
        startsWith: 'an instinctive ability that embodies',
        values: ['How might you embody', 'How might you embrace'],
        imageTherapyPrefixes: {
            reflecting: ['Where might you not be able to embody', 'When might you be unable to embrace'],
            expanding: ['What other options do you have to embrace', 'What other choices do you have to make the most of'],
            acting: ['What steps can you now take to make the most of', 'What actions will you now take to display'],
        }
    },
    {
        startsWith: 'an instinctive ability to',
        values: questionPrefixes,
        imageTherapyPrefixes: {
            reflecting: ['might you not be able', 'might you be unable'],
            expanding: ['What other options do you have', 'What different choices do you have'],
            acting: ['What specific actions will you take to', 'What actions will you take to'],
        }
    },
    {
        startsWith: 'an instinctive awareness that',
        values: ['What would you say', 'What do you feel'],
        imageTherapyPrefixes: {
            reflecting: ['What would you say hinders rather than', 'What hinders rather than'],
            expanding: ['What other option do you have that', 'What other alternative do you have that'],
            acting: ['What action can you now take that', 'What specific step can you now take that'],
        }
    },
    {
        startsWith: 'an instinctive capacity to',
        values: questionPrefixes,
        imageTherapyPrefixes: {
            reflecting: ['might you not be able', 'might you be unable'],
            expanding: ['What other options do you have', 'What different choces do you have'],
            acting: ['What specific actions will you take to', 'What actions will you take to'],
        }
    },
    {
        startsWith: 'an instinctive opportunity to',
        values: questionPrefixes,
        imageTherapyPrefixes: {
            reflecting: ['might you not be able', 'might you be unable'],
            expanding: ['What other options do you have', 'What different choces do you have'],
            acting: ['What specific actions will you take to', 'What actions will you take to'],
        }
    },
    {
        startsWith: 'a mood that can colour your perspective',
        values: ['How might you change your perspective', 'How might you change your viewpoint'],
        imageTherapyPrefixes: {
            reflecting: ['Where do you feel unable to change your perspective', 'Where do you feel that you can\'t change your viewpoint'],
            expanding: ['How might you expand your current perspective', 'How might you move beyond your present viewpoint'],
            acting: ['What steps can you take to expand your current perspective', 'Wahact actions can you take to move beyond your present viewpoint'],
        }
    },
    {
        startsWith: 'a mood that can shift your perspective',
        values: ['How might you shift your perspective'],
        imageTherapyPrefixes: {
            reflecting: ['Where do you feel unable to change your perspective', 'Where do you feel that you can\'t change your viewpoint'],
            expanding: ['How might you expand your current perspective', 'How might you move beyond your present viewpoint'],
            acting: ['What steps can you take to expand your current perspective', 'Wahact actions can you take to move beyond your present viewpoint'],
        }
    },
    {
        startsWith: 'a natural ability for a seemingly',
        values: ['How might you grow', 'Where might you investigate'],
        imageTherapyPrefixes: {
            reflecting: ['Where might you not be able to grow', 'How might you  be unable to grow'],
            expanding: ['What interesting options do have in this situation to grow', 'What are your potential chocies to grow'],
            acting: ['What actions can you now take to grow', 'What positive steps can you now take to grow'],
        }
    },
    {
        startsWith: 'a natural ability for developing your',
        values: ['Where might you', 'How might you'],
        imageTherapyPrefixes: {
            reflecting: ['Where might you not be able', 'Where might you be unable'],
            expanding: ['What other options do you have to', 'What are your choices to'],
            acting: ['What actions can you now take to', 'What positive steps can you now take to'],
        }
    },
    {
        startsWith: 'a natural ability for your',
        values: ['How might you enable your', 'Where might you use your'],
        imageTherapyPrefixes: {
            reflecting: ['Where might you not be able to use your', 'Where might you be unable to use your'],
            expanding: ['What other opportunities do you have to use your', 'What other opportunities do you have to use your'],
            acting: ['What actions can you now take to use your', 'What positive steps can you now take to use your'],
        }
    },
    {
        startsWith: 'a natural ability to',
        values: ['Where might you be able to'],
        imageTherapyPrefixes: {
            reflecting: ['might you not be able', 'might you be unable'],
            expanding: ['What other options do you have to', 'What different choices do you have to'],
            acting: ['What specific actions will you take to', 'What actions will you take to'],
        }
    },
    {
        startsWith: 'a natural awareness',
        values: ['How might you become more aware', 'Where might you become more aware'],
        imageTherapyPrefixes: {
            reflecting: ['Where might you not realise', 'When might you be unaware of'],
            expanding: ['Where might you expand your awareness', 'How might you increase your understanding'],
            acting: ['What steps can you take to expand your awareness', 'What action can you take to increase your understanding'],
        }
    },
    {
        startsWith: 'a natural capacity to',
        values: questionPrefixes,
        imageTherapyPrefixes: {
            reflecting: ['might you not be able to', 'might you be unable to'],
            expanding: ['What other options do you have to', 'What different choces do you have to'],
            acting: ['What specific actions will you take to', 'What actions will you take to'],
        }
    },
    {
        startsWith: 'a natural opportunity to',
        values: ['When might you be able to'],
        imageTherapyPrefixes: {
            reflecting: ['might you not be able to', 'might you be unable to'],
            expanding: ['What other options do you have to', 'What different choces do you have to'],
            acting: ['What specific actions will you take to', 'What actions will you take to'],
        }
    },
    {
        startsWith: 'a natural potential for',
        values: ['How might you explore your'],
        imageTherapyPrefixes: {
            reflecting: ['What might be stopping you from exploring', 'What might be preventing you from exploring'],
            expanding: ['What opportunities do you have to explore', 'What choices do you have to explore'],
            acting: ['What specific actions will you take to explore', 'What actions will you take to explore'],
        }
    },
    {
        startsWith: 'a natural potential to',
        values: questionPrefixes,
        imageTherapyPrefixes: {
            reflecting: ['might you not be able', 'might you be unable'],
            expanding: ['What other options do you have', 'What different choces do you have'],
            acting: ['What specific actions will you take to', 'What actions will you take to'],
        }
    },
    {
        startsWith: 'a natural progression where you can',
        values: ['Where can you', 'In what situations can you'],
        imageTherapyPrefixes: {
            reflecting: ['Where can\'t you', 'In what situations can\'t you'],
            expanding: ['In what other situations can you', 'In what different situations can you'],
            acting: ['What actions can you now take so you can', 'What steps can you now take so you can'],
        }
    },
    {
        startsWith: 'an opportunity for a',
        values: ['Where might you have an opportunity for', 'When might you have an opportunity for'],
        imageTherapyPrefixes: {
            reflecting: ['Where might you have little opportunity for', 'Where might you have less opportunity for'],
            expanding: ['What other options do you have for', 'What different choices do you have for'],
            acting: ['What specific actions will you take for', 'What actions will you take for'],
        }
    },
    {
        startsWith: 'an opportunity for you to',
        values: suffixedQuestionPrefixes('personally'),
        imageTherapyPrefixes: {
            reflecting: ['might you not be able', 'might you be unable'],
            expanding: ['What other options do you have', 'What different choices do you have'],
            acting: ['What specific actions will you take to', 'What actions will you take to'],
        }
    },
    {
        startsWith: 'an opportunity to',
        values: questionPrefixes,
        imageTherapyPrefixes: {
            reflecting: ['might you not be able', 'might you be unable'],
            expanding: ['What other options do you have', 'What different choces do you have'],
            acting: ['What specific actions will you take to', 'What actions will you take to'],
        }
    },
    {
        startsWith: 'a potential ability to go',
        values: ['Where are you going', 'Where have you been going'],
        imageTherapyPrefixes: {
            reflecting: ['might you not be able', 'might you be unable'],
            expanding: ['What other opportunities do you have', 'What other choices do you have'],
            acting: ['What actions can you now take', 'What steps will you now take'],
        }
    },
    {
        startsWith: 'a potential opportunity',
        values: ['When can you'],
        imageTherapyPrefixes: {
            reflecting: ['might you not be able', 'might you be unable'],
            expanding: ['What other opportunities do you have', 'What other choices do you have'],
            acting: ['What actions can you now take', 'What steps will you now take'],
        }
    },
    {
        startsWith: 'a potential to',
        values: questionPrefixes,
        imageTherapyPrefixes: {
            reflecting: ['might you not be able', 'might you be unable'],
            expanding: ['What other options do you have', 'What different choces do you have'],
            acting: ['What specific actions will you take to', 'What actions will you take to'],
        }
    },
    {
        startsWith: 'a realisation about',
        values: ['How might you become more aware of', 'Where have you become more aware of'],
        imageTherapyPrefixes: {
            reflecting: ['Where might you be less aware of', 'When might you be less aware of'],
            expanding: ['How might you increase your awareness of', 'How might you increase your understanding of'],
            acting: ['What actions can you take to increase your awareness of', 'What steps can you take to increase your understanding of'],
        }
    },
    {
        startsWith: 'a realisation of',
        values: ['How might you become more aware of', 'Where might you become more aware of'],
        imageTherapyPrefixes: {
            reflecting: ['Where might you be less aware of', 'When might you be less aware of'],
            expanding: ['How might you increase your awareness of', 'How might you increase your understanding of'],
            acting: ['What actions can you take to increase your awareness of', 'What steps can you take to increase your understanding of'],
        }
    },
    {
        startsWith: 'a realisation that',
        values: ['How might you become more aware that', 'Where can you become more aware that'],
        imageTherapyPrefixes: {
            reflecting: ['Where might you be less aware that', 'When might you be less aware that'],
            expanding: ['How might you increase your awareness that', 'How might you increase your understanding that'],
            acting: ['What actions can you take to increase your awareness that', 'What steps can you take to increase your understanding that'],
        }
    },
    {
        startsWith: 'a realisation that you understand',
        values: ['How might you become more aware that', 'Where can you become more aware that'],
        imageTherapyPrefixes: {
            reflecting: ['Where might you be less aware that', 'When might you be less aware that'],
            expanding: ['How might you increase your awareness that', 'How might you increase your understanding that'],
            acting: ['What actions can you take to increase your awareness that', 'What steps can you take to increase your understanding that'],
        }
    },
    {
        startsWith: 'a realisation you can',
        values: ['How might you become more aware that', 'Where can you become more aware that'],
        imageTherapyPrefixes: {
            reflecting: ['Where might you be less aware that', 'When might you be less aware that'],
            expanding: ['How might you increase your awareness that', 'How might you increase your understanding that'],
            acting: ['What actions can you take to increase your awareness that', 'What steps can you take to increase your understanding that'],
        }
    },
    {
        startsWith: 'a situation that can',
        values: ['What do you feel might', 'What do you think might'],
        imageTherapyPrefixes: {
            reflecting: ['What do you feel might not', 'What do you think might not'],
            expanding: ['What other options do you have that might', 'What other opportunities do you have that might'],
            acting: ['What steps can you now take that might', 'What actions can you now take that will'],
        }
    },
    {
        startsWith: 'a situation where you are',
        values: ['Where might you be', 'how might you be'],
        imageTherapyPrefixes: {
            reflecting: ['Where might you not be', 'how might you not be'],
            expanding: ['What other options do you have for', 'What other choices do you have for'],
            acting: ['What actions will you now take for', 'What steps will you now take for'],
        }
    },
    {
        startsWith: 'a situation where you can',
        values: ['Where can you', 'Where might you'],
        imageTherapyPrefixes: {
            reflecting: ['Where might you not', 'Where can you not'],
            expanding: ['What other choices do you have to', 'What other options do you have to'],
            acting: ['What actions can you now take to', 'What steps can you now take to'],
        }
    },
    {
        startsWith: 'a situation where you feel',
        values: ['Where can you feel', 'When might you feel'],
        imageTherapyPrefixes: {
            reflecting: ['Where do you not feel', 'When do you not feel'],
            expanding: ['What other choices do you have to feel', 'What other options might you have to feel'],
            acting: ['What actions can you take to feel', 'What steps can you now take to feel'],
        }
    },
    {
        startsWith: 'a situation where you give',
        values: suffixedQuestionPrefixes('give'),
        imageTherapyPrefixes: {
            reflecting: ['Where might you not be able to give', 'When might you be unable to give'],
            expanding: ['What other choices do you have to give', 'What other options might you have to give'],
            acting: ['What actions can you take to give', 'What steps can you now take to give'],
        }
    },
    {
        startsWith: 'a situation where you have',
        values: ['Where might you have', 'When might you have'],
        imageTherapyPrefixes: {
            reflecting: ['When might you not be able', 'Where might you be unable'],
            expanding: ['What are your other options', 'What are your alternatives'],
            acting: ['What actions can you take to', 'What steps can you now take to'],
        }
    },
    {
        startsWith: 'a situation where you have been',
        values: ['Where might you have been', 'How might you have been'],
        imageTherapyPrefixes: {
            reflecting: ['Where might you not have been', 'How might you not have been'],
            expanding: ['What are your other options', 'What are your alternatives'],
            acting: ['What actions can you take to', 'What steps can you now take to'],
        }
    }
];
