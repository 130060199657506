var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { uuidv4 } from "../utils/get-uuid-v4";
import templater from "./templater";
;
var Bot = /** @class */ (function () {
    function Bot(options) {
        var _this = this;
        this.options = options;
        this.context = {};
        this.privateContext = {};
        this.conversationId = undefined;
        this.conversation = [];
        this.dialogStack = [];
        this.dialogMap = {};
        this.startDialog = 'main';
        this.start = function (dialogName) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (dialogName) {
                            this.startDialog = dialogName;
                        }
                        return [4 /*yield*/, this.reset()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.runScript(this.startDialog)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.runStep()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        this.loadScripts = function (scripts) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.dialogMap = scripts
                    .flatMap(function (script) { return Object.keys(script).map(function (key) { return ({ key: key, value: script[key] }); }); })
                    .reduce(function (acc, cur) {
                    acc[cur.key] = cur.value;
                    return acc;
                }, {});
                return [2 /*return*/];
            });
        }); };
        this.write = function (text, isUser) {
            if (isUser === void 0) { isUser = false; }
            return __awaiter(_this, void 0, void 0, function () {
                var message;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            message = {
                                date: new Date().toISOString(),
                                text: text,
                                id: uuidv4(),
                                isUser: isUser,
                            };
                            this.conversation.push(message);
                            return [4 /*yield*/, ((_b = (_a = this.options).onMessage) === null || _b === void 0 ? void 0 : _b.call(_a, this))];
                        case 1:
                            _d.sent();
                            return [4 /*yield*/, ((_c = this.listener) === null || _c === void 0 ? void 0 : _c.call(this, message))];
                        case 2:
                            _d.sent();
                            return [2 /*return*/];
                    }
                });
            });
        };
        this.isWaitingForInput = undefined;
        this.waitForInput = function (variableName) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.isWaitingForInput = variableName;
                return [2 /*return*/];
            });
        }); };
        this.runScript = function (dialogName) { return __awaiter(_this, void 0, void 0, function () {
            var dialog;
            var _a;
            return __generator(this, function (_b) {
                dialog = this.dialogMap[dialogName];
                if (dialog) {
                    (_a = this.dialogStack).push.apply(_a, (__spreadArray([], dialog, true).reverse()));
                }
                return [2 /*return*/];
            });
        }); };
        this.runStep = function () { return __awaiter(_this, void 0, void 0, function () {
            var step;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(!this.isWaitingForInput && this.dialogStack.length > 0)) return [3 /*break*/, 3];
                        step = this.dialogStack.pop();
                        if (!step) return [3 /*break*/, 2];
                        return [4 /*yield*/, step(this)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [3 /*break*/, 0];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.reset = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.conversationId = uuidv4();
                this.isWaitingForInput = undefined;
                this.conversation = [];
                this.dialogStack = [];
                return [2 /*return*/];
            });
        }); };
        this.onHear = function (text) { return __awaiter(_this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.conversation.push({
                            date: new Date().toISOString(),
                            text: text,
                            id: uuidv4(),
                            isUser: true,
                        });
                        return [4 /*yield*/, ((_b = (_a = this.options).onMessage) === null || _b === void 0 ? void 0 : _b.call(_a, this))];
                    case 1:
                        _c.sent();
                        if (!this.isWaitingForInput) return [3 /*break*/, 3];
                        this.context[this.isWaitingForInput] = text;
                        this.isWaitingForInput = undefined;
                        return [4 /*yield*/, this.runStep()];
                    case 2:
                        _c.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        if (!!this.dialogStack.length) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.start()];
                    case 4:
                        _c.sent();
                        _c.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        this.getConnector = function (onSay) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.listener = onSay;
                return [2 /*return*/, {
                        onHear: this.onHear,
                    }];
            });
        }); };
        this.loadScripts(options.scripts);
    }
    return Bot;
}());
export { Bot };
export var say = function (template) { return function (bot) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    return [2 /*return*/, bot.write(templater(template, bot.context))];
}); }); }; };
export var ask = function (variableName) { return function (bot) { return bot.waitForInput(variableName); }; };
export var run = function (scriptName) { return function (bot) { return bot.runScript(scriptName); }; };
export var condition = function (predicate, step, elseStep) { return function (bot) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!predicate(bot.context)) return [3 /*break*/, 2];
                return [4 /*yield*/, step(bot)];
            case 1:
                _a.sent();
                return [3 /*break*/, 4];
            case 2:
                if (!elseStep) return [3 /*break*/, 4];
                return [4 /*yield*/, elseStep(bot)];
            case 3:
                _a.sent();
                _a.label = 4;
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var quit = function () { return function (bot) { return bot.reset(); }; };
// const quitScript = {
//   onQuit: [
//     say('Would you like to take a break or stop this conversation?'),
//     ask('quit_intent'),
//     condition(({ quit_intent }) => quit_intent === 'quit', quit(),
//       run('resume')),
//   ],
//   resume: [
//     say('Ok, let\'s take a break, just say hello to resume'),
//     ask('resume')
//   ]
// }
