import { box, randomBytes } from "tweetnacl";
import { decodeBase64, decodeUTF8, encodeBase64, encodeUTF8 } from "tweetnacl-util";
// To create receiver keys:
// const keypair = box.keyPair();
// const receiverPublicKey = encodeBase64(keypair.publicKey);
// const receiverSecretKey = encodeBase64(keypair.secretKey);
export var encrypt = function (receiverPublicKey, msgParams) {
    var ephemeralKeyPair = box.keyPair();
    var pubKeyUInt8Array = decodeBase64(receiverPublicKey);
    var msgParamsUInt8Array = decodeUTF8(msgParams);
    var nonce = randomBytes(box.nonceLength);
    var encryptedMessage = box(msgParamsUInt8Array, nonce, pubKeyUInt8Array, ephemeralKeyPair.secretKey);
    return {
        ciphertext: encodeBase64(encryptedMessage),
        ephemPubKey: encodeBase64(ephemeralKeyPair.publicKey),
        nonce: encodeBase64(nonce),
        version: "x25519-xsalsa20-poly1305"
    };
};
export var decrypt = function (receiverSecretKey, encryptedData) {
    var receiverSecretKeyUint8Array = decodeBase64(receiverSecretKey);
    var nonce = decodeBase64(encryptedData.nonce);
    var ciphertext = decodeBase64(encryptedData.ciphertext);
    var ephemPubKey = decodeBase64(encryptedData.ephemPubKey);
    var decryptedMessage = box.open(ciphertext, nonce, ephemPubKey, receiverSecretKeyUint8Array);
    if (!decryptedMessage) {
        return undefined;
    }
    return encodeUTF8(decryptedMessage);
};
