var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { box } from "tweetnacl";
import { encodeBase64 } from "tweetnacl-util";
import { uuidv4 } from "./get-uuid-v4";
import { DateTime } from "luxon";
import { encrypt } from "../utils/encryption";
export default (function () { return __awaiter(void 0, void 0, void 0, function () {
    var uri_1, properties_1, keysJson, keys_1, userId_1, chatHistoryJson, chatHistory, lastSavedIso, lastSaved_1, conversations, journal;
    var _a;
    return __generator(this, function (_b) {
        try {
            if (window.location.host.startsWith('localhost')) {
                console.log('Ignore Localhost');
                return [2 /*return*/];
            }
            console.log('Non-localhost');
            uri_1 = 'https://ohcfnnd767.execute-api.eu-west-1.amazonaws.com/prod/process';
            properties_1 = {
                keys: 'keys',
                userId: 'sessionId',
                lastSaved: 'lastConversationDate',
                chatHistory: 'chatHistory',
            };
            keysJson = localStorage.getItem(properties_1.keys);
            keys_1 = keysJson ? JSON.parse(keysJson) : getSeedKeys();
            userId_1 = (_a = localStorage.getItem(properties_1.userId)) !== null && _a !== void 0 ? _a : uuidv4();
            chatHistoryJson = localStorage.getItem(properties_1.chatHistory);
            chatHistory = chatHistoryJson ? JSON.parse(chatHistoryJson) : {};
            lastSavedIso = localStorage.getItem(properties_1.lastSaved);
            lastSaved_1 = lastSavedIso ? DateTime.fromISO(lastSavedIso) : DateTime.now().minus({ years: 10 });
            conversations = Object.values(chatHistory)
                .sort(function (chat) { return chat.dateStarted; })
                .filter(function (chat) { return chat.messages.length
                && chat.conversationId !== 'journal'
                && chat.messages.find(function (m) { return m.isUser; })
                && DateTime.fromISO(chat.dateStarted) > lastSaved_1; });
            journal = chatHistory === null || chatHistory === void 0 ? void 0 : chatHistory.journal;
            if (journal) {
                conversations.push(__assign(__assign({}, journal), { conversationId: "".concat(userId_1, "-journal"), dateStarted: DateTime.now().toISO() }));
            }
            conversations.forEach(function (c) { return __awaiter(void 0, void 0, void 0, function () {
                var encrypted, conversation, response;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            encrypted = encrypt(keys_1.publicKey, JSON.stringify(c));
                            conversation = __assign({ sessionId: userId_1, key: keys_1.privateKey, id: c.conversationId }, encrypted);
                            return [4 /*yield*/, fetch("".concat(uri_1), {
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                    body: JSON.stringify(conversation),
                                    method: 'POST',
                                    // mode: 'no-cors',
                                    // cache: 'no-store',
                                })];
                        case 1:
                            response = _a.sent();
                            if (response.status === 200 || response.status === 0) {
                                window.localStorage.setItem(properties_1.lastSaved, c.dateStarted);
                            }
                            return [2 /*return*/];
                    }
                });
            }); });
            window.localStorage.setItem(properties_1.keys, JSON.stringify(keys_1));
            window.localStorage.setItem(properties_1.userId, userId_1);
        }
        catch (err) {
            console.error(err);
        }
        return [2 /*return*/];
    });
}); });
var getSeedKeys = function () {
    var keypair = box.keyPair();
    return {
        publicKey: encodeBase64(keypair.publicKey),
        privateKey: encodeBase64(keypair.secretKey),
    };
};
