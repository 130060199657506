var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import compromise from 'compromise';
import _ from 'lodash';
var terms = function (text) {
    var cleanedText = text.replace(/[^\w\s]/g, '');
    var doc = compromise(cleanedText);
    var words = doc
        .terms()
        .out('array');
    return words.map(function (term) { return term === null || term === void 0 ? void 0 : term.toLowerCase(); });
};
var rank = function (sentence, rapidModel) {
    return _(terms(sentence).reduce(function (acc, word) {
        var rapidMatch = rapidModel === null || rapidModel === void 0 ? void 0 : rapidModel[word];
        return __spreadArray(__spreadArray([], acc, true), [rapidMatch ? rapidMatch[2][1] : 0], false);
    }, [])).mean();
};
export default (function (text, rapidModel) {
    var doc = compromise(text);
    var statements = doc
        .clauses()
        .out('array');
    var trimmed = statements.map(function (sentence) {
        var trimmed = sentence.trim();
        if (trimmed.endsWith(',')) {
            return trimmed.substring(0, trimmed.length - 1);
        }
        return trimmed;
    });
    var ranked = _(trimmed).map(function (sentence) { return ({
        sentence: sentence,
        rank: rank(sentence, rapidModel),
    }); })
        .sortBy(function (_a) {
        var rank = _a.rank;
        return -rank;
    })
        .value();
    return ranked;
});
