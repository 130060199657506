var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import _ from 'lodash';
import nlp from 'compromise';
import { toSecondPerson } from './to-second-person';
import { randomArrayValue } from "./common/random-array-value";
import { imageQuestionConjunctionSplitters, questionSuffixes, imageQuestionPrefixMappings } from './common/statement-to-question-mappings';
import resources from '../static';
import rapidRank from '../utils/rapid-rank';
var questionTemplates = [
    'You mentioned {statement}. Can you explain a little more about that?',
    'You spoke about {statement}'
];
export var statementToQuestion = function (input, exactMatch, allImages, rapidModel) {
    var _a;
    var imageDictionaryMatch = undefined;
    var selectedChunk = undefined;
    if (exactMatch) {
        imageDictionaryMatch = allImages
            .find(function (i) { return input.toLowerCase() === i.Symbol.toLowerCase(); });
        if (!imageDictionaryMatch) {
            return;
        }
    }
    else {
        var textToMatch = input;
        if (rapidModel) {
            var ranked = rapidRank(input, rapidModel);
            if (ranked.length) {
                textToMatch = ranked[0].sentence;
            }
        }
        var chunks = nlp(textToMatch).nouns().not('#Pronoun').out('array'); // Match words with image dict.
        var chunk_1 = (_a = randomArrayValue(chunks)) === null || _a === void 0 ? void 0 : _a.toLowerCase();
        if (!chunk_1) {
            return;
        }
        imageDictionaryMatch = _.sortBy(allImages, function (i) { return -i.Symbol.length; })
            .find(function (i) { return chunk_1.toLowerCase().includes(i.Symbol.toLowerCase()); });
        selectedChunk = chunk_1;
    }
    var question = undefined;
    if (imageDictionaryMatch) {
        var prefixMapping = imageQuestionPrefixMappings.find(function (mapping) {
            return imageDictionaryMatch === null || imageDictionaryMatch === void 0 ? void 0 : imageDictionaryMatch.SymbolDefinition.toLowerCase().startsWith(mapping.startsWith.toLowerCase());
        });
        if (prefixMapping) {
            var replaceWith = randomArrayValue(prefixMapping.values);
            question = imageDictionaryMatch.SymbolDefinition.replace(prefixMapping.startsWith, replaceWith);
            var splitIndex = imageQuestionConjunctionSplitters
                .map(function (s) { return question === null || question === void 0 ? void 0 : question.indexOf(" ".concat(s, " ")); })
                .find(function (i) { return i !== -1; });
            if (splitIndex) {
                question = question.substring(0, splitIndex);
            }
        }
    }
    else {
        if (!selectedChunk) {
            return;
        }
        var secondPerson = toSecondPerson(selectedChunk);
        var template = randomArrayValue(questionTemplates);
        question = template
            .replace('{statement}', secondPerson);
    }
    if (!question) {
        return;
    }
    var suffix = randomArrayValue(questionSuffixes);
    return "".concat(question.trim(), " ").concat(suffix, "?");
};
export default (function (params) { return function (bot) { return __awaiter(void 0, void 0, void 0, function () {
    var out, input, exactMatch, allImages, rapidModel, question;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                out = params.out;
                bot.context[out] = undefined;
                input = bot.context[params.input];
                exactMatch = params.exactMatch;
                return [4 /*yield*/, resources.image()];
            case 1:
                allImages = _a.sent();
                return [4 /*yield*/, resources.rapid()];
            case 2:
                rapidModel = _a.sent();
                if (typeof input !== 'string') {
                    return [2 /*return*/];
                }
                question = statementToQuestion(input, !!exactMatch, allImages, rapidModel);
                bot.context[out] = question;
                return [2 /*return*/];
        }
    });
}); }; });
