import React from 'react'
import { Link } from 'react-router-dom';
import modules from '../modules';

const Home = () => {
  const friendlyName = process.env.FRIENDLY_NAME;

  return (<>
    <h1 className='text-3xl pb-6 text-black'>Welcome to <strong>{friendlyName}</strong></h1>
    <p className='text-black'><strong>{friendlyName}</strong> is a unique conversational technology. It uses trusted and proven therapeutic processes to provide you with a private and secure conversational space.</p>
    <br />
    <p className='text-black'>The conversational space that <strong>{friendlyName}</strong> creates for you enables you to understand your deeper feelings and explore your wider options. It is future-focused, helping you to become who you truly want to be.</p>
    <br />
    <div className='min-h-max grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 auto-cols-auto content-center'>
      {modules.map((module) => (
        <div
          key={module.name}
          className=''
        >
          <Link id={`module-${module.name}`} className='min-h-full inline-block' to={module.path}>
            <div className='h-64 w-64 bg-[#7f7fff] text-white text-center grid place-content-center cursor-pointer rounded-lg text-2xl shadow-lg hover:shadow-2xl'>
              {module.name}
            </div>
          </Link>
        </div>
      ))}
    </div>
    <br />
    <h2 className='text-xl pb-10 text-black'>Please choose an app by clicking on it</h2>
    <p className='text-black'>
      The <a href="./clean-therapy"><strong>Clean Therapy</strong></a> app helps you to explore your thoughts.
      Just answer the questions being asked, usually with what comes into your mind first.
      When it asks for a mental image, use an image like <em>'blue sky', 'stormy sea', 'red balloon'</em> - just whatever mental image that reflects how you are currently feeling.
      Click 'Send' to have a conversation to explore your thoughts.
      Try not to overthink your answers.
      <br />
      <br />
      The <a href="./cognitive-therapy"><strong>Cognitive Therapy</strong></a> app helps you to explore your deeper feelings.
      Just answer the questions being asked, taking your time to let your deeper feelings surface.
      Click 'Send' to have a conversation to explore your deeper feelings.
      Try not to have preconceptions and be open to what emerges for you.
      <br />
      <br />
      The <a href="./therapy-journal"><strong>Therapy Journal</strong></a> app helps you to collect your thoughts.
      Type your thoughts in and click 'Post' to collect them.
      <br />
      <br />
      The <strong>History</strong> function stores your conversations securely, privately and locally in your individual web browser.
      It is a very useful resource for revisiting your past thoughts and feelings.
      <br />
      <br />
      To return to this start page from any of the apps, just use the Back button on your browser.
    </p>
    <br />
    <br />
    <br />
    <div className='text-white bg-[#7f7fff]'><strong>{friendlyName}</strong> is built using <a href="https://gyral.ltd/" target="_blank"><strong>Gyral</strong></a> Curious Technology.
    </div>
  </>)
}
export default Home;