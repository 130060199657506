import React, { useState, useEffect } from 'react';
import { AlwaysScrollToBottom } from './AlwaysScrollToBottom';
import { Button, Input } from '@mui/material';
import { uuidv4 } from '../utils/get-uuid-v4';
import { useAppContext } from './StateProvider';
import MessageHistory from './MessageHistory';
import { inference } from '../emotion-inference/inference';

const EmotionDemo = () => {
  const [messages, setMessages] = useState([]);
  const [text, setText] = useState('');

  const appendMessage = async (message, isUser) => {
    const newMessage =
    {
      date: new Date().toISOString(),
      id: uuidv4(),
      text: message,
      isUser,
    };

    setMessages((existing) => [...existing, newMessage]);
  }

  const onSend = async () => {
    const message = text;
    setText('');

    if (!message?.trim().length) {
      return;
    }

    appendMessage(message, true);
    const result = await inference(message);
    const emotionResults = result[1].slice(1).map(v=> `${v[1]}% ${v[0]}`).join('\r\n');

    appendMessage(emotionResults, false);
  }

  const onKeyPress = async (ev) => {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      await onSend();
    }
  }

  const onChange = (ev) => {
    setText(ev.target.value);
  }

  return (
    <>
      <h1 className='text-3xl pb-6'>Emotion Inference</h1>
      <div className="flex flex-col">
        <div id="chat-history" className="h-72 overflow-y-auto">
          <MessageHistory messages={messages} />
          <AlwaysScrollToBottom />
        </div>
        <div className="h-10 flex flex-row flex-grow justify-between">
          <Input
            type='text'
            onChange={onChange}
            onKeyDown={onKeyPress}
            value={text}
            fullWidth={true}
            placeholder='Message'
            inputProps={{
              "aria-autocomplete": 'none',
              autoComplete: 'off'
            }}
            sx={{ mr: 2 }}
            id='input-text'
          />
          <Button
            onClick={onSend}
            style={{
              backgroundColor: 'black',
              color: 'white',
              textTransform: 'none'
            }}
            id='button-send'
          >
            Post
          </Button>
        </div>
      </div>
    </>
  );
}

export default EmotionDemo;