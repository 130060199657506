import { ask, condition, run, say } from "../../bot/bot-schema";
import setState from '../../actions/set-state';
import yesno from "../../actions/yesno";
import images from "../../actions/images";
import imageDefinition from "../../actions/image-definition";
import statementToQuestion from "../../actions/statement-to-question";
export default {
    image_therapy: [
        setState({ "keep_going": undefined }),
        setState({ "image_description": undefined }),
        setState({ "specific_image": undefined }),
        say('Please describe the image that you want to explore.'),
        ask('image_description'),
        images({ input: "image_description", out: "image_list" }),
        condition(function (context) { return context.image_list; }, say('The images that you have shared are {{ image_list }}. Which image would you like to find out more about?')),
        condition(function (context) { return context.image_list; }, run('ask_specific_image')),
        condition(function (context) { return !context.image_list; }, run('image_therapy'))
    ],
    ask_specific_image: [
        ask('specific_image'),
        statementToQuestion({ input: "specific_image", out: "image_question", exactMatch: true }),
        condition(function (context) { return !context.image_question; }, say('Please choose one of the specific images identified in your image description')),
        condition(function (context) { return !context.image_question; }, run('ask_specific_image')),
        condition(function (context) { return context.image_question; }, imageDefinition({ input: "specific_image", out: "image_definition" })),
        condition(function (context) { return context.image_question; }, say('{{ specific_image }} represents {{ image_definition }}')),
        condition(function (context) { return context.image_question; }, run('ask_questions')),
    ],
    ask_questions: [
        say('{{ image_question }}'),
        ask('response1'),
        say('And where can you use your energy and self-motivation to keep your commitments balanced?'),
        ask('response2'),
        say('What might be stopping you as you make progress towards your chosen ambition?'),
        ask('response3'),
        say('What other options mught you have to make progress towards your chosen ambition?'),
        ask('response4'),
        say('What specific actions can you take to make progress towards your chosen ambition?'),
        ask('response5'),
        say('Would you like to explore any of the other images (yes/no)?'),
        ask('continue_image_therapy'),
        yesno({ input: "continue_image_therapy", out: "keep_going" }),
        condition(function (context) { return !context.keep_going; }, say('Thanks for sharing those images and looking forward to exploring more with you next time.')),
        condition(function (context) { return context.keep_going; }, run('image_therapy')),
    ],
};
