import React, { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "../styles/main.css";
// import Contact from './Contact';

function Header() {
	const navRef = useRef();

	const showHeader = () => {
		navRef.current.classList.toggle(
			"responsive_nav"
		);
	};

	return (
		<header>
			<h3><strong><a href="/">NeuMe</a></strong></h3>
			<nav ref={navRef}>
				<a href="/">Home</a>
				<a href="/clean-therapy">Clean Therapy</a>
				<a href="/cognitive-therapy">Cognitive Therapy</a>
				<a href="/therapy-journal">Therapy Journal</a>
				<a href="/history">History</a>
				{/* <a href="/contact">Contact</a> */}
				<button
					className="nav-btn nav-close-btn"
					onClick={showHeader}>
					<FaTimes />
				</button>
			</nav>
			<button
				className="nav-btn"
				onClick={showHeader}>
				<FaBars />
			</button>
		</header>
	);
}

export default Header;