export default (function (template, context) {
    var pattern = /{{\s?(\w+)\s?}}/g;
    var matches = template.match(pattern);
    var formatted = !matches ? template : matches === null || matches === void 0 ? void 0 : matches.reduce(function (acc, match) {
        var variableName = match.substring(2, match.length - 2).trim();
        var value = context[variableName];
        if (!value) {
            return acc;
        }
        return acc.replaceAll(match, value.toString());
    }, template);
    return formatted;
});
