var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect } from 'react';
import { AlwaysScrollToBottom } from './AlwaysScrollToBottom';
import { Button, Input } from '@mui/material';
import { uuidv4 } from '../utils/get-uuid-v4';
import { useAppContext } from './StateProvider';
import MessageHistory from './MessageHistory';
import { illuminatorAnalysis } from '../utils/illuminator-analysis';
import resources from '../static';
var getIlluminator = resources.illuminator();
var Journal = function () {
    var context = useAppContext();
    var _a = useState([]), messages = _a[0], setMessages = _a[1];
    var _b = useState(''), text = _b[0], setText = _b[1];
    var _c = context.chatHistory, chatHistory = _c[0], setChatHistory = _c[1];
    var appendMessage = function (message, isUser) { return __awaiter(void 0, void 0, void 0, function () {
        var newMessage;
        return __generator(this, function (_a) {
            newMessage = {
                date: new Date().toISOString(),
                id: uuidv4(),
                text: message,
                isUser: isUser,
            };
            setMessages(function (existing) { return __spreadArray(__spreadArray([], existing, true), [newMessage], false); });
            return [2 /*return*/];
        });
    }); };
    useEffect(function () {
        var journal = chatHistory['journal'];
        if (journal) {
            setMessages(journal.messages);
        }
    }, []);
    useEffect(function () {
        var setHistory = function () { return __awaiter(void 0, void 0, void 0, function () {
            var existingConversation, illuminator, analysis, newState;
            var _a;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!messages) {
                            return [2 /*return*/];
                        }
                        existingConversation = (_b = chatHistory['journal']) !== null && _b !== void 0 ? _b : {
                            dateStarted: Date.now(),
                            conversationId: 'journal',
                            context: {},
                        };
                        return [4 /*yield*/, getIlluminator];
                    case 1:
                        illuminator = _c.sent();
                        analysis = illuminatorAnalysis(messages.map(function (m) { return m.text; }).join(' '), illuminator);
                        newState = __assign(__assign({}, chatHistory), (_a = {}, _a['journal'] = __assign(__assign({}, existingConversation), { messages: messages, context: {
                                analysis: analysis,
                            } }), _a));
                        setChatHistory(newState);
                        return [2 /*return*/];
                }
            });
        }); };
        setHistory();
    }, [messages]);
    var onSend = function () { return __awaiter(void 0, void 0, void 0, function () {
        var message;
        return __generator(this, function (_a) {
            message = text;
            setText('');
            if (!(message === null || message === void 0 ? void 0 : message.trim().length)) {
                return [2 /*return*/];
            }
            appendMessage(message, true);
            return [2 /*return*/];
        });
    }); };
    var onKeyPress = function (ev) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(ev.key === 'Enter')) return [3 /*break*/, 2];
                    ev.preventDefault();
                    return [4 /*yield*/, onSend()];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var onChange = function (ev) {
        setText(ev.target.value);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex flex-col" },
            React.createElement("div", { id: "chat-history", className: "overflow-y-auto" },
                React.createElement(MessageHistory, { messages: messages }),
                React.createElement("div", { className: 'h-24' }),
                React.createElement(AlwaysScrollToBottom, null)),
            React.createElement("div", { className: "fixed bottom-0 w-8/12 flex flex-row flex-grow justify-between bg-gray-100 py-4" },
                React.createElement(Input, { type: 'text', onChange: onChange, onKeyDown: onKeyPress, value: text, fullWidth: true, placeholder: 'Message', inputProps: {
                        "aria-autocomplete": 'none',
                        autoComplete: 'off'
                    }, sx: { mr: 2 }, id: 'input-text' }),
                React.createElement(Button, { onClick: onSend, style: {
                        backgroundColor: 'black',
                        color: 'white',
                        textTransform: 'none'
                    }, id: 'button-send' }, "Post")))));
};
export default Journal;
