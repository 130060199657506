import { ask, condition, run, say } from "../../bot/bot-schema";
import setState from '../../actions/set-state';
import assessresponse from '../../actions/assess-response';
export default {
    assessor_phq9: [
        setState({ "phq9_tally_sum": 0 }),
        setState({ "phq9_tally": undefined }),
        setState({ "last_question": false }),
        say('This questionnaire is important in order to provide you with the best possible health care. Your answers will facilitate the understanding of problems that you may have.'),
        say('In the past 2 weeks, how often have you been bothered by any of the following problems?'),
        run('phq9_Q1'),
    ],
    assess_phq9_response: [
        ask('response'),
        assessresponse({ input: "response", question_name: "phq9_question", tally_name: "phq9_tally" }),
        condition(function (ctx) { return ctx.response_unclear; }, say('{{ response_unclear }}')),
        condition(function (ctx) { return ctx.response_unclear; }, run('assess_phq9_response')),
        condition(function (ctx) { return !ctx.response_unclear && !ctx.last_question; }, say('Thanks, next question')),
    ],
    phq9_Q1: [
        setState({ "phq9_question": 1 }),
        say('Little interest or pleasure in doing things?'),
        run('assess_phq9_response'),
        run('phq9_Q2'),
    ],
    phq9_Q2: [
        setState({ "phq9_question": 2 }),
        say('Feeling down, depressed, or hopeless?'),
        run('assess_phq9_response'),
        run('phq9_Q3'),
    ],
    phq9_Q3: [
        setState({ "phq9_question": 3 }),
        say('Trouble falling or staying asleep, or sleeping too much?'),
        run('assess_phq9_response'),
        run('phq9_Q4'),
    ],
    phq9_Q4: [
        setState({ "phq9_question": 4 }),
        say('Feeling tired or having little energy?'),
        run('assess_phq9_response'),
        run('phq9_Q5'),
    ],
    phq9_Q5: [
        setState({ "phq9_question": 5 }),
        say('Poor appetite or overeating?'),
        run('assess_phq9_response'),
        run('phq9_Q6'),
    ],
    phq9_Q6: [
        setState({ "phq9_question": 6 }),
        say('Feeling bad about yourself, or that you are a failure or have let yourself or your family down?'),
        run('assess_phq9_response'),
        run('phq9_Q7'),
    ],
    phq9_Q7: [
        setState({ "phq9_question": 7 }),
        say('Trouble concentrating on things, such as reading the newspaper or watching television?'),
        run('assess_phq9_response'),
        run('phq9_Q8'),
    ],
    phq9_Q8: [
        setState({ "phq9_question": 8 }),
        say('Moving or speaking so slowly that other people could have noticed?'),
        say('Or the opposite - being so fidgety or restless that you have been moving around a lot more than usual?'),
        run('assess_phq9_response'),
        run('phq9_Q9'),
    ],
    phq9_Q9: [
        setState({ "phq9_question": 9 }),
        say('Thoughts that you would be better off dead, or of hurting yourself in some way?'),
        setState({ "last_question": true }),
        run('assess_phq9_response'),
        run('phq9_end'),
    ],
    phq9_end: [
        say('Thanks for your responses.'),
        condition(function (context) { return Number(context.phq9_tally_sum) <= 4; }, say('Your assessment indicates that you may be experiencing No Depression. If you are concerned about your mental health, please contact your doctor or a mental health professional.')),
        condition(function (context) { return Number(context.phq9_tally_sum) >= 5 && Number(context.phq9_tally_sum) <= 9; }, say('Your assessment indicates that you may be experiencing Mild Depression. If you are concerned about your mental health, please contact your doctor or a mental health professional.')),
        condition(function (context) { return Number(context.phq9_tally_sum) >= 10 && Number(context.phq9_tally_sum) <= 14; }, say('Your assessment indicates that you may be experiencing Moderate Depression. If you are concerned about your mental health, please contact your doctor or a mental health professional.')),
        condition(function (context) { return Number(context.phq9_tally_sum) >= 15 && Number(context.phq9_tally_sum) <= 19; }, say('Your assessment indicates that you may be experiencing Moderately Severe Depression. If you are concerned about your mental health, please contact your doctor or a mental health professional.')),
        condition(function (context) { return Number(context.phq9_tally_sum) >= 20; }, say('Your assessment indicates that you may be experiencing Severe Depression. If you are concerned about your mental health, please contact your doctor or a mental health professional.')),
    ],
};
