import { ask, condition, run, say } from "../../bot/bot-schema";
import lastFeeling from '../../actions/last-feeling';
import toLowers from '../../actions/to-lowers';
import getRandom from '../../actions/get-random';
import randomOption from "../../actions/random-option";
import getContext from '../../actions/get-context';
import lastNoun from '../../actions/last-noun';
export default {
    clean_language: [
        // ***PREVIOUS IMAGE***
        getContext({ key: "image", from: "latest", out: "last_image" }),
        randomOption({ out: "feeling_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var feeling_question = _a.feeling_question;
            return feeling_question === "option1";
        }, say("How are you feeling today?")),
        condition(function (_a) {
            var feeling_question = _a.feeling_question;
            return feeling_question === "option2";
        }, say("What's on your mind?")),
        condition(function (_a) {
            var feeling_question = _a.feeling_question;
            return feeling_question === "option3";
        }, say("What is currently on your mind?")),
        condition(function (_a) {
            var feeling_question = _a.feeling_question;
            return feeling_question === "option4";
        }, say("What is currently occupying your thoughts?")),
        condition(function (_a) {
            var feeling_question = _a.feeling_question;
            return feeling_question === "option5";
        }, say("What's been on your mind?")),
        condition(function (_a) {
            var feeling_question = _a.feeling_question;
            return feeling_question === "option6";
        }, say("What feelings have been emerging for you?")),
        condition(function (_a) {
            var feeling_question = _a.feeling_question;
            return feeling_question === "option7";
        }, say("What feelings have you been experiencing?")),
        ask('feeling'),
        lastFeeling({ input: "feeling" }),
        toLowers({ input: "feeling" }),
        // run('image0'),
        run('image1'),
        run('assess_image_response'),
        run('image2'),
        run('image3'),
        getRandom({ out: "previousimage", p: 0.7 }),
        condition(function (_a) {
            var previousimage = _a.previousimage;
            return previousimage;
        }, run('previousimage')),
        run('image4'),
        run('image5'),
        run('image6'),
        run('image7'),
        run('image8'),
        run('image9'),
        run('image10'),
        run('image11'),
        run('image12'),
        run('image13'),
        run('bye'),
    ],
    // image0: [
    //   getContext({ key: "image", from: "latest", out: "image" }),
    //   getRandom({ out: "use_previous_image", p: 0.0 }),
    //   condition(({ image, use_previous_image, feeling }) => image && use_previous_image && feeling,
    //     say('Last time you mentioned the {{ image }}, is this relevant to feeling {{ feeling }}?')),
    //   // run('image1'),
    // ],
    image1: [
        randomOption({ out: "image1_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var image1_question = _a.image1_question;
            return image1_question === "option1";
        }, say("Thanks for sharing that feeling and so you can explore it further, please share a mental image that you associate with that feeling?")),
        condition(function (_a) {
            var image1_question = _a.image1_question;
            return image1_question === "option2";
        }, say("Thanks for sharing that feeling. Often the best way to understand feelings is by using mental imagery, so what mental image do you associate with that feeling?")),
        condition(function (_a) {
            var image1_question = _a.image1_question;
            return image1_question === "option3";
        }, say("Often the best way to understand feelings is by using mental imagery, so what mental image might you associate with that feeling?")),
        condition(function (_a) {
            var image1_question = _a.image1_question;
            return image1_question === "option4";
        }, say("Working with mental imagery is often the best way to really understand and explore feelings, so please share a mental image that you associate with that feeling?")),
        condition(function (_a) {
            var image1_question = _a.image1_question;
            return image1_question === "option5";
        }, say("Thanks for sharing that feeling. Often the the best way to explore your feelings is by using mental imagery, so what mental image do you associate with that feeling?")),
        condition(function (_a) {
            var image1_question = _a.image1_question;
            return image1_question === "option6";
        }, say("The best way to understand feelings is often by using mental imagery, so what mental image might you associate with that feeling?")),
        condition(function (_a) {
            var image1_question = _a.image1_question;
            return image1_question === "option7";
        }, say("The best way to understand your feelings is often by using mental imagery, the language of your mind, so what mental image might you associate with that feeling?")),
    ],
    assess_image_response: [
        ask('image'),
        lastNoun({ input: 'image' }),
        condition(function (ctx) { return !ctx.image; }, run('reprompt_image')),
        condition(function (ctx) { return !ctx.image; }, run('assess_image_response')),
    ],
    reprompt_image: [
        randomOption({ out: "reprompt_image_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var reprompt_image_question = _a.reprompt_image_question;
            return reprompt_image_question === "option1";
        }, say("The reason for asking for an image is that it is one of the most powerful ways to describe how you are really feeling. Don't overthink it and just answer with whatever image emerges in your mind. So what mental image might you associate with that feeling?")),
        condition(function (_a) {
            var reprompt_image_question = _a.reprompt_image_question;
            return reprompt_image_question === "option2";
        }, say("Mental imagery is often the best way to describe complex feelings. The mental image does not need to be complex, it can be quite simple, whatever is floating into your mind when you think about your current situation. What mental image emerges for you when you think about how you are feeling?")),
        condition(function (_a) {
            var reprompt_image_question = _a.reprompt_image_question;
            return reprompt_image_question === "option3";
        }, say("Mental images are a great way to represent complex feelings, even though the images themselves can be quite simple, like 'dark cloud' or 'stormy sea'. For you personally, what mental image floats into your mind when you think about how you feel in your current situation?")),
        condition(function (_a) {
            var reprompt_image_question = _a.reprompt_image_question;
            return reprompt_image_question === "option4";
        }, say("The conversational process we are using here is known as Clean Therapy. It is a very effective and gentle way to explore complex feelings by representing them as mental images. So when you think about how you are feeling in this situation what mental image emerges for you?")),
        condition(function (_a) {
            var reprompt_image_question = _a.reprompt_image_question;
            return reprompt_image_question === "option5";
        }, say("It might feel as if there is no mental image emerging for you and if that is the case, just relax, take a deep breath, and just let a mental image float into your mind. And as you do that, what mental image emerged for you that describes how you are feeling in your current situation?")),
        condition(function (_a) {
            var reprompt_image_question = _a.reprompt_image_question;
            return reprompt_image_question === "option6";
        }, say("If you are unsure about saying what mental image represents your feeling, just think of it as a noun with maybe an adjective to describe it, like 'big mountain' or 'distant island'. So what mental image is emerging for you that might represent how you feel?")),
        condition(function (_a) {
            var reprompt_image_question = _a.reprompt_image_question;
            return reprompt_image_question === "option7";
        }, say("Mental imagery is a powerful way to describe your emotions. Even quite a simple mental image can be used to explore deeper unidentified emotions. So what mental image floats into your mind when you consider your current situation?")),
    ],
    image2: [
        randomOption({ out: "image2_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var image2_question = _a.image2_question;
            return image2_question === "option1";
        }, say("And what kind of {{ image }} is that?")),
        condition(function (_a) {
            var image2_question = _a.image2_question;
            return image2_question === "option2";
        }, say("And what sort of {{ image }} is that?")),
        condition(function (_a) {
            var image2_question = _a.image2_question;
            return image2_question === "option3";
        }, say("And what type of {{ image }} is that?")),
        condition(function (_a) {
            var image2_question = _a.image2_question;
            return image2_question === "option4";
        }, say("And that {{ image }}, what kind of {{ image }} is that?")),
        condition(function (_a) {
            var image2_question = _a.image2_question;
            return image2_question === "option5";
        }, say("And that {{ image }}, what sort of {{ image }} is that?")),
        condition(function (_a) {
            var image2_question = _a.image2_question;
            return image2_question === "option6";
        }, say("And that {{ image }}, what type of {{ image }} is that?")),
        condition(function (_a) {
            var image2_question = _a.image2_question;
            return image2_question === "option7";
        }, say("And that {{ image }} that you have shared, what type of {{ image }} is that?")),
        ask('image2'),
    ],
    image3: [
        randomOption({ out: "image3_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var image3_question = _a.image3_question;
            return image3_question === "option1";
        }, say("And what else is there about that {{ image }}?")),
        condition(function (_a) {
            var image3_question = _a.image3_question;
            return image3_question === "option2";
        }, say("And what other things are there about that {{ image }}?")),
        condition(function (_a) {
            var image3_question = _a.image3_question;
            return image3_question === "option3";
        }, say("And what else do you associate with that {{ image }}?")),
        condition(function (_a) {
            var image3_question = _a.image3_question;
            return image3_question === "option4";
        }, say("And what other feelings do you have about that {{ image }}?")),
        condition(function (_a) {
            var image3_question = _a.image3_question;
            return image3_question === "option5";
        }, say("And what other thoughts do you have about that {{ image }}?")),
        condition(function (_a) {
            var image3_question = _a.image3_question;
            return image3_question === "option6";
        }, say("And what other feelings emerge for you when you focus on that {{ image }}?")),
        condition(function (_a) {
            var image3_question = _a.image3_question;
            return image3_question === "option7";
        }, say("And what else comes to mind for you when you focus on that {{ image }}?")),
        ask('image3'),
    ],
    // ***PREVIOUS IMAGE LOOP***
    previousimage: [
        randomOption({ out: "previousimage_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var previousimage_question = _a.previousimage_question;
            return previousimage_question === "option1";
        }, say('Last time you mentioned the {{ last_image }}, how might that be connected to {{ image }}?')),
        condition(function (_a) {
            var previousimage_question = _a.previousimage_question;
            return previousimage_question === "option2";
        }, say('Last time you mentioned the {{ last_image }}, how might that be connected to {{ image }}?')),
        condition(function (_a) {
            var previousimage_question = _a.previousimage_question;
            return previousimage_question === "option3";
        }, say('Last time you mentioned the {{ last_image }}, how might that be connected to {{ image }}?')),
        condition(function (_a) {
            var previousimage_question = _a.previousimage_question;
            return previousimage_question === "option4";
        }, say('Last time you mentioned the {{ last_image }}, how might that be connected to {{ image }}?')),
        condition(function (_a) {
            var previousimage_question = _a.previousimage_question;
            return previousimage_question === "option5";
        }, say('Last time you mentioned the {{ last_image }}, how might that be connected to {{ image }}?')),
        condition(function (_a) {
            var previousimage_question = _a.previousimage_question;
            return previousimage_question === "option6";
        }, say('Last time you mentioned the {{ last_image }}, how might that be connected to {{ image }}?')),
        condition(function (_a) {
            var previousimage_question = _a.previousimage_question;
            return previousimage_question === "option7";
        }, say('Last time you mentioned the {{ last_image }}, how might that be connected to {{ image }}?')),
        ask('image_connection')
    ],
    image4: [
        randomOption({ out: "image4_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var image4_question = _a.image4_question;
            return image4_question === "option1";
        }, say("And where is that {{ image }} located?")),
        condition(function (_a) {
            var image4_question = _a.image4_question;
            return image4_question === "option2";
        }, say("And where about is that {{ image }}?")),
        condition(function (_a) {
            var image4_question = _a.image4_question;
            return image4_question === "option3";
        }, say("And whereabouts is that {{ image }}?")),
        condition(function (_a) {
            var image4_question = _a.image4_question;
            return image4_question === "option4";
        }, say("And where would you say that {{ image }} is located?")),
        condition(function (_a) {
            var image4_question = _a.image4_question;
            return image4_question === "option5";
        }, say("And that {{ image }} that has emerged for you, where about is it?")),
        condition(function (_a) {
            var image4_question = _a.image4_question;
            return image4_question === "option6";
        }, say("And that {{ image }} that has emerged for you, where about would you say it is?")),
        condition(function (_a) {
            var image4_question = _a.image4_question;
            return image4_question === "option7";
        }, say("And that {{ image }}, where would you say it is located?")),
        ask('image4'),
    ],
    image5: [
        randomOption({ out: "image5_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var image5_question = _a.image5_question;
            return image5_question === "option1";
        }, say("And where might that {{ image }} come from?")),
        condition(function (_a) {
            var image5_question = _a.image5_question;
            return image5_question === "option2";
        }, say("And where might that {{ image }} emerge from?")),
        condition(function (_a) {
            var image5_question = _a.image5_question;
            return image5_question === "option3";
        }, say("And what might be the source of that {{ image }}?")),
        condition(function (_a) {
            var image5_question = _a.image5_question;
            return image5_question === "option4";
        }, say("And that {{ image }}, where might it come from?")),
        condition(function (_a) {
            var image5_question = _a.image5_question;
            return image5_question === "option5";
        }, say("And that {{ image }}, where might it emerge from?")),
        condition(function (_a) {
            var image5_question = _a.image5_question;
            return image5_question === "option6";
        }, say("And whhere might that {{ image }} have originated from?")),
        condition(function (_a) {
            var image5_question = _a.image5_question;
            return image5_question === "option7";
        }, say("And that {{ image }}, where might it have originated from?")),
        ask('image5'),
    ],
    image6: [
        randomOption({ out: "image6_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var image6_question = _a.image6_question;
            return image6_question === "option1";
        }, say("And what happens just before the {{ image }} appears?")),
        condition(function (_a) {
            var image6_question = _a.image6_question;
            return image6_question === "option2";
        }, say("And how do you feel just before the {{ image }} appears?")),
        condition(function (_a) {
            var image6_question = _a.image6_question;
            return image6_question === "option3";
        }, say("And what happens just before the {{ image }} emerges?")),
        condition(function (_a) {
            var image6_question = _a.image6_question;
            return image6_question === "option4";
        }, say("And just before the {{ image }} appears, what is happening then?")),
        condition(function (_a) {
            var image6_question = _a.image6_question;
            return image6_question === "option5";
        }, say("And just before the {{ image }} appears, how are you feeling at that moment?")),
        condition(function (_a) {
            var image6_question = _a.image6_question;
            return image6_question === "option6";
        }, say("And just before the {{ image }} emerges, how are you feeling in that moment?")),
        condition(function (_a) {
            var image6_question = _a.image6_question;
            return image6_question === "option7";
        }, say("And how are you feeling just before the {{ image }} emerges?")),
        ask('image6'),
    ],
    image7: [
        randomOption({ out: "image7_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var image7_question = _a.image7_question;
            return image7_question === "option1";
        }, say("And what would you like to have happen when the {{ image }} appears?")),
        condition(function (_a) {
            var image7_question = _a.image7_question;
            return image7_question === "option2";
        }, say("And what would you like to happen for you when the {{ image }} appears?")),
        condition(function (_a) {
            var image7_question = _a.image7_question;
            return image7_question === "option3";
        }, say("And what would you like to have happen when the {{ image }} emerges?")),
        condition(function (_a) {
            var image7_question = _a.image7_question;
            return image7_question === "option4";
        }, say("And when the {{ image }} appears, what would you like to have happen ?")),
        condition(function (_a) {
            var image7_question = _a.image7_question;
            return image7_question === "option5";
        }, say("And when the {{ image }} appears, what would you like to happen for you?")),
        condition(function (_a) {
            var image7_question = _a.image7_question;
            return image7_question === "option6";
        }, say("And when the {{ image }} emerges, what would you like to have happen?")),
        condition(function (_a) {
            var image7_question = _a.image7_question;
            return image7_question === "option7";
        }, say("And when the {{ image }} emerges, what would be your preferred outcome?")),
        ask('image7'),
    ],
    image8: [
        randomOption({ out: "image8_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var image8_question = _a.image8_question;
            return image8_question === "option1";
        }, say("And what is the connection between you and the {{ image }}?")),
        condition(function (_a) {
            var image8_question = _a.image8_question;
            return image8_question === "option2";
        }, say("And what do you feel is the connection between you and the {{ image }}?")),
        condition(function (_a) {
            var image8_question = _a.image8_question;
            return image8_question === "option3";
        }, say("And what do you think connects you to the {{ image }}?")),
        condition(function (_a) {
            var image8_question = _a.image8_question;
            return image8_question === "option4";
        }, say("And what would you say is the connection between you and the {{ image }}?")),
        condition(function (_a) {
            var image8_question = _a.image8_question;
            return image8_question === "option5";
        }, say("And what do you feel connects you to the {{ image }}?")),
        condition(function (_a) {
            var image8_question = _a.image8_question;
            return image8_question === "option6";
        }, say("And what do you think is making that connection between you and the {{ image }}?")),
        condition(function (_a) {
            var image8_question = _a.image8_question;
            return image8_question === "option7";
        }, say("And what do you feel is making that connection between you and the {{ image }}?")),
        ask('image8'),
    ],
    image9: [
        randomOption({ out: "image9_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var image9_question = _a.image9_question;
            return image9_question === "option1";
        }, say("And how do you feel about the connection between you and the {{ image }}?")),
        condition(function (_a) {
            var image9_question = _a.image9_question;
            return image9_question === "option2";
        }, say("And how do you feel about that connection between you and the {{ image }}?")),
        condition(function (_a) {
            var image9_question = _a.image9_question;
            return image9_question === "option3";
        }, say("And how do you feel about your connection to the {{ image }}?")),
        condition(function (_a) {
            var image9_question = _a.image9_question;
            return image9_question === "option4";
        }, say("And that connection between you and the {{ image }}, how do you feel about that?")),
        condition(function (_a) {
            var image9_question = _a.image9_question;
            return image9_question === "option5";
        }, say("And when you focus on the connection between you and the {{ image }}, how do you feel about that?")),
        condition(function (_a) {
            var image9_question = _a.image9_question;
            return image9_question === "option6";
        }, say("And when you think about your connection to the {{ image }}, what feelings emerge for you?")),
        condition(function (_a) {
            var image9_question = _a.image9_question;
            return image9_question === "option7";
        }, say("And when you consider your connection to the {{ image }}, what feelings emerge for you?")),
        ask('image9'),
    ],
    image10: [
        randomOption({ out: "image10_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var image10_question = _a.image10_question;
            return image10_question === "option1";
        }, say("And what type of connection would you like to have between you and the {{ image }}?")),
        condition(function (_a) {
            var image10_question = _a.image10_question;
            return image10_question === "option2";
        }, say("And what sort of connection would you like to have between you and the {{ image }}?")),
        condition(function (_a) {
            var image10_question = _a.image10_question;
            return image10_question === "option3";
        }, say("And what type of connection would you like between you and the {{ image }}?")),
        condition(function (_a) {
            var image10_question = _a.image10_question;
            return image10_question === "option4";
        }, say("And because you always have a choice, what type of connection would you like to have between you and the {{ image }}?")),
        condition(function (_a) {
            var image10_question = _a.image10_question;
            return image10_question === "option5";
        }, say("And if you had to choose, what sort of connection would you like to have between you and the {{ image }}?")),
        condition(function (_a) {
            var image10_question = _a.image10_question;
            return image10_question === "option6";
        }, say("And what would be your preferred type of connection would you like between you and the {{ image }}?")),
        condition(function (_a) {
            var image10_question = _a.image10_question;
            return image10_question === "option7";
        }, say("And because you have the power to choose, what type of connection would you like between you and the {{ image }}?")),
        ask('image10'),
    ],
    image11: [
        randomOption({ out: "image11_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var image11_question = _a.image11_question;
            return image11_question === "option1";
        }, say("And what would you like to happen to the {{ image }}?")),
        condition(function (_a) {
            var image11_question = _a.image11_question;
            return image11_question === "option2";
        }, say("And what would you like to alter with that {{ image }}?")),
        condition(function (_a) {
            var image11_question = _a.image11_question;
            return image11_question === "option3";
        }, say("And what would you change with that {{ image }}?")),
        condition(function (_a) {
            var image11_question = _a.image11_question;
            return image11_question === "option4";
        }, say("And because you have the power to change that {{ image }}, what would you like to happen to the {{ image }}?")),
        condition(function (_a) {
            var image11_question = _a.image11_question;
            return image11_question === "option5";
        }, say("And if you had to choose, what would you like to change with that {{ image }}?")),
        condition(function (_a) {
            var image11_question = _a.image11_question;
            return image11_question === "option6";
        }, say("And because you have the power to transform that {{ image }}, what would you like to have happen to it?")),
        condition(function (_a) {
            var image11_question = _a.image11_question;
            return image11_question === "option7";
        }, say("And what would you prefer to have happen to that {{ image }}?")),
        ask('image11'),
    ],
    image12: [
        randomOption({ out: "image12_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var image12_question = _a.image12_question;
            return image12_question === "option1";
        }, say("And how can you make that happen?")),
        condition(function (_a) {
            var image12_question = _a.image12_question;
            return image12_question === "option2";
        }, say("And what action can you take to make that happen?")),
        condition(function (_a) {
            var image12_question = _a.image12_question;
            return image12_question === "option3";
        }, say("And what steps can you take to make that happen?")),
        condition(function (_a) {
            var image12_question = _a.image12_question;
            return image12_question === "option4";
        }, say("And how can you make that happen, so you can reach your preferred outcome?")),
        condition(function (_a) {
            var image12_question = _a.image12_question;
            return image12_question === "option5";
        }, say("And to make that happen, what specifi actions can you take?")),
        condition(function (_a) {
            var image12_question = _a.image12_question;
            return image12_question === "option6";
        }, say("And to make that happen, what are the steps can you take?")),
        condition(function (_a) {
            var image12_question = _a.image12_question;
            return image12_question === "option7";
        }, say("And what are the practical steps you can take to make that happen?")),
        ask('image12'),
    ],
    image13: [
        randomOption({ out: "image13_question", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var image13_question = _a.image13_question;
            return image13_question === "option1";
        }, say("And what will be the outcome when you make that happen?")),
        condition(function (_a) {
            var image13_question = _a.image13_question;
            return image13_question === "option2";
        }, say("And what do you feel the outcome will be when you make that happen?")),
        condition(function (_a) {
            var image13_question = _a.image13_question;
            return image13_question === "option3";
        }, say("And what do you sense the outcome will be when you make that happen?")),
        condition(function (_a) {
            var image13_question = _a.image13_question;
            return image13_question === "option4";
        }, say("And when you make that outcome happen, how will that feel for you?")),
        condition(function (_a) {
            var image13_question = _a.image13_question;
            return image13_question === "option5";
        }, say("And when you make your chosen outcome happen, how will that feel for you?")),
        condition(function (_a) {
            var image13_question = _a.image13_question;
            return image13_question === "option6";
        }, say("And when you make your preferred outcome happen, how will that feel for you?")),
        condition(function (_a) {
            var image13_question = _a.image13_question;
            return image13_question === "option7";
        }, say("And when you achieve your chosen outcome, how will that feel for you?")),
        ask('image13'),
    ],
    bye: [
        randomOption({ out: "bye", options: ["option1", "option2", "option3", "option4", "option5", "option6", "option7"] }),
        condition(function (_a) {
            var bye = _a.bye;
            return bye === "option1";
        }, say("Thanks for sharing your feelings with me just now, bye for now.")),
        condition(function (_a) {
            var bye = _a.bye;
            return bye === "option2";
        }, say("Thanks for that conversation, I hope you found it useful. Bye for now.")),
        condition(function (_a) {
            var bye = _a.bye;
            return bye === "option3";
        }, say("Thanks for that conversation, I hope you found it of value. Goodbye, until the next time.")),
        condition(function (_a) {
            var bye = _a.bye;
            return bye === "option4";
        }, say("Thanks for sharing your feelings with me just now, bye for now.")),
        condition(function (_a) {
            var bye = _a.bye;
            return bye === "option5";
        }, say("Thanks for sharing how you have been feeling, I hope you found it useful. Bye for now.")),
        condition(function (_a) {
            var bye = _a.bye;
            return bye === "option6";
        }, say("Thank you for sharing your thoughts and feelings, I hope you found it of value. Bye, until the next time.")),
        condition(function (_a) {
            var bye = _a.bye;
            return bye === "option7";
        }, say("Thanks for that conversation, I hope you found it of value. Goodbye, until the next time.")),
        ask('bye'),
    ],
};
