export var illuminatorAnalysis = function (text, illuminator) {
    var words = text.match(/\b[\w']+\b/g);
    if (!words) {
        return {};
    }
    var wordMap = words.map(function (w) { return w.toLowerCase(); }).reduce(function (acc, word) {
        var _a;
        if (!acc[word]) {
            acc[word] = {
                arc: (_a = illuminator[word]) !== null && _a !== void 0 ? _a : 0,
                count: 1,
            };
        }
        else {
            acc[word].count++;
        }
        return acc;
    }, {});
    var frequencyMap = Object.keys(wordMap).reduce(function (acc, key) {
        if (acc[wordMap[key].arc.toString()]) {
            acc[wordMap[key].arc.toString()]++;
        }
        else {
            acc[wordMap[key].arc.toString()] = 1;
        }
        return acc;
    }, {});
    return {
        wordMap: wordMap,
        frequencyMap: frequencyMap
    };
};
